import { AutocompleteOwnerState, AutocompleteRenderOptionState, Box } from "@mui/material";
import { Patient } from "../types/patient";

export const renderPatientOption = (
  { key, ...optionProps }: any,
  option: Patient,
  state: AutocompleteRenderOptionState,
  ownerState: AutocompleteOwnerState<Patient, false, false, false, 'div'>,
) => (
  <Box
    key={key}
    component='li'
    sx={theme => ({
      color: option.dummyFlag ? theme.palette.info.light : (option.finished ? theme.palette.text.disabled : theme.palette.text.primary),
    })}
    {...optionProps}
  >
    {ownerState.getOptionLabel(option)}
  </Box>
)
