import React from "react";
import { Button } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "../../redux/hooks";
import { forgotPassword, login, selectIsLoggingIn } from "../../redux/slices/account";
import { LoginData } from "../../types/auth";
import { removeUndefined, validateEmail } from "../../utils/global";
import { validateLogin } from "../../utils/auth";
import FormTextInput from "../../components/form/FormTextInput";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { Form } from "./Form";

const Login = () => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(selectIsLoggingIn);

  const [forgotPwOpen, setForgotPwOpen] = React.useState(false);

  const handleLogin = (values: LoginData) => {
    dispatch(login(values));
  }

  const handleForgotPassword = ({ email }: { email: string }) => {
    dispatch(forgotPassword({ email }))
    .then(() => {
      setForgotPwOpen(false);
    })
  }

  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={validateLogin}
        onSubmit={handleLogin}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormTextInput
              required
              label='Email'
              name='email'
              type='email'
              autoComplete='username'
              values={values}
              errors={errors}
              onChange={handleChange}
              sx={{ mt: 3, mb: 2 }}
            />
            <FormTextInput
              required
              label='Password'
              name='password'
              type='password'
              autoComplete='current-password'
              values={values}
              errors={errors}
              onChange={handleChange}
            />

            <Button variant='text' disabled={isSubmitting} onClick={() => setForgotPwOpen(true)} sx={{ alignSelf: 'center', my: 1 }}>
              Forgot Password?
            </Button>
            <Button type='submit' disabled={isSubmitting} sx={{ alignSelf: 'center' }}>
              Login
            </Button>
          </Form>
        )}
      </Formik>

      <BaseFormDialog
        open={forgotPwOpen}
        onClose={() => setForgotPwOpen(false)}
        title='Enter your email to receive a recovery link'
        initialValues={{ email: '' }}
        validate={({ email }) => removeUndefined({ email: validateEmail(email) })}
        onSubmit={handleForgotPassword}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
        }) => (
          <FormTextInput
            required
            label='Email'
            name='email'
            type='email'
            autoComplete='username'
            values={values}
            errors={errors}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        )}
      </BaseFormDialog>
    </>
  );
}

export default Login;