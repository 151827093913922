import { Autocomplete, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { demoteMidwife, selectSites } from "../../redux/slices/account";
import { getAccountFullName, removeUndefined, validateNotEmpty } from "../../utils/global";
import { DialogCloseHandler } from "../../types/dialog";
import { AccountData, SiteData } from "../../types/account";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";

type DemoteData = {
  site: SiteData | null,
}

const DialogDemote = ({
  midwife,
  open,
  onClose,
}: {
  midwife?: AccountData,
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const sites = useSelector(selectSites);

  const handleValidate = ({ site }: DemoteData) => removeUndefined({
    site: validateNotEmpty(site?.id),
  })

  const handleSubmit = ({ site }: DemoteData) => {
    if (!midwife || !site) return;

    dispatch(demoteMidwife({ midwife: midwife.username, siteId: site.id }))
    .then(() => { onClose?.({}, 'closeClick') });
  }

  return (
    <BaseFormDialog
      title={`Restrict ${getAccountFullName(midwife)}'s access to a single site`}
      open={open}
      onClose={onClose}
      initialValues={{ site: null } as DemoteData}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleBlur,
        setFieldValue,
      }) => (
        <>
          <Autocomplete
            disablePortal
            value={values.site}
            options={sites}
            getOptionLabel={site => site.name}
            onChange={(e, val) => { setFieldValue('site', val) }}
            onBlur={handleBlur}
            isOptionEqualToValue={(option: any, value) => value.id === option.id}
            renderInput={params => <TextField
              {...params}
              required
              name='site'
              label='Site'
              error={!!errors.site}
              helperText={errors.site}
            />}
          />

          <Typography variant='caption' sx={theme => ({ color: theme.palette.secondary.main, mt: 2 })}>
            Note: this midwife must not have any users assigned to a different site
          </Typography>
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogDemote;