import { toast, ToastOptions } from "react-toastify";
import { ApiErrorResponse } from "../api/types/base";
import { Toast, ToastText } from "../components/Toast";
import { ResponseCode } from "../api/types/responseCode";

/**
 * 
 * @param error 
 * @param text Error message to show if request was rejected. If function is provided, message can depend on error code
 * @param options
 * @returns 
 */
export const showErrorMessage = (
  error: ApiErrorResponse,
  text?: ToastText | ((error: ApiErrorResponse & {type: 'errorResponse'}) => ToastText | undefined),
  options?: ToastOptions,
) => {
  // If request was aborted -> do not show error message
  if (error.type === 'noRequest') {
    return;
  }
  
  // console.log(error)
  let toastText: ToastText | undefined = undefined;
  let toastOptions                      = options;

  switch (error.type) {
    case 'noResponse':
      // if (toast.isActive('noResponse')) {
      //   console.warn('noResponse toast is already visible');
      // }

      toastText = {
        title: 'No response from server',
        message: 'Please check your connection and try again',
      }
      toastOptions = { ...toastOptions, toastId: 'noResponse' };
      break;

    case 'errorResponse':
      console.error(error)

      if (error.code?.id === ResponseCode.JwtTokenExpiredException) {
        toastText    = { title: 'Session expired', message: 'Please login again' };
        toastOptions = { ...toastOptions, toastId: 'sessionExpired' };
      }
      else {
        toastText = (typeof(text) === 'function') ? text(error) : text;
      }
      break;
  }

  if (!toastText) {
    toastText = { title: 'Unknown error' }
  }

  Toast.error(toastText, toastOptions);
}