import React from "react";
import { SxProps } from "@mui/material";
import {
  AddRounded as AddIcon,
  WarehouseRounded as StockIcon,
  AddLink as LinkIcon,
  LocalShippingRounded as DeliveryIcon,
  DownloadRounded as DownloadIcon,
} from "@mui/icons-material";
import { useSelector } from "../../redux/hooks";
import { selectRole } from "../../redux/slices/account";
import { selectAvailableTestkits } from "../../redux/slices/testkit";
import { AccountRole } from "../../types/account";
import { DEFAULT_API_COUNT } from "../../utils/testkit";
import Section from "../../components/Section";
import DialogGenerateNewIDs from "./DialogGenerateNewIDs";
import StockButton from "./StockButton";
import DialogAddUser from "./DialogAddUser";
import DialogStockKits from "./DialogStockKits";
import DialogDispatchKits from "./DialogDispatchKits";
import DialogDownload from "./DialogDownload";
import DialogRequestKits from "./DialogRequestKits";

const ControlSection = ({
  sx,
}: {
  sx?: SxProps,
}) => {
  const role = useSelector(selectRole);
  const availableKits = useSelector(selectAvailableTestkits);

  const [dialogOpenNewIDs, setDialogOpenNewIDs] = React.useState(false);
  const [dialogOpenStockKits, setDialogOpenStockKits] = React.useState(false);
  const [dialogOpenDispatchKits, setDialogOpenDispatchKits] = React.useState(false);
  const [dialogOpenDownload, setDialogOpenDownload] = React.useState(false);

  const [dialogOpenRequestKits, setDialogOpenRequestKits] = React.useState(false);
  const [dialogOpenIssueToUser, setDialogOpenIssueToUser] = React.useState(false);

  return (
    <Section sx={{ alignSelf: 'stretch', minWidth: 600, gap: 0.5, px: 2, ...sx }}>
      {role === AccountRole.ADMIN ? (
        <>
          <StockButton
            title='Generate New Study IDs'
            // hint='XX IDs currently available'
            description='Generate new unasssigned IDs to apply to new kits'
            icon={<AddIcon fontSize='large' color='primary'/>}
            onClick={() => setDialogOpenNewIDs(true)}
          />
          <DialogGenerateNewIDs open={dialogOpenNewIDs} onClose={() => setDialogOpenNewIDs(false)}/>
    
          <StockButton
            title='Update Testkit Stock Info'
            description='Assign batch info to new stock kits or update existing stock'
            icon={<StockIcon fontSize='large' color='primary'/>}
            onClick={() => setDialogOpenStockKits(true)}
          />
          <DialogStockKits open={dialogOpenStockKits} onClose={() => setDialogOpenStockKits(false)}/>

          <StockButton
            title='Allocate Testkits'
            hint='Only for allocating non-standard quantity of kits'
            description='Allocate testkits to site, pending dispatch'
            icon={<LinkIcon fontSize='large' color='primary'/>}
            onClick={() => setDialogOpenRequestKits(true)}
          />
          <DialogRequestKits open={dialogOpenRequestKits} onClose={() => setDialogOpenRequestKits(false)}/>
    
          <StockButton
            title='Dispatch Testkits'
            // hint='XX kits current awaiting dispatch'
            description="Assign tracking numbers to allocated kits ready for delivery"
            icon={<DeliveryIcon fontSize='large' color='primary'/>}
            onClick={() => setDialogOpenDispatchKits(true)}
          />
          <DialogDispatchKits open={dialogOpenDispatchKits} onClose={() => setDialogOpenDispatchKits(false)}/>
    
          <StockButton
            title='Download Testkit Data'
            description='Download testkit data from the server as a CSV file'
            icon={<DownloadIcon fontSize='large' color='primary'/>}
            onClick={() => setDialogOpenDownload(true)}
          />
          <DialogDownload open={dialogOpenDownload} onClose={() => setDialogOpenDownload(false)}/>
        </>
      ) : (
        <>
          <StockButton
            title='Request Kits'
            description='Request delivery of additional kits'
            icon={<DeliveryIcon fontSize='large' color='primary'/>}
            onClick={() => setDialogOpenRequestKits(true)}
          />
          <DialogRequestKits open={dialogOpenRequestKits} onClose={() => setDialogOpenRequestKits(false)}/>

          <StockButton
            title='Issue Kit to User'
            hint={`${availableKits.length}${availableKits.length === DEFAULT_API_COUNT ? '+' : ''} kits available`}
            description='Add user details to assign kit to user'
            icon={<AddIcon fontSize='large' color='primary'/>}
            onClick={() => setDialogOpenIssueToUser(true)}
          />
          <DialogAddUser open={dialogOpenIssueToUser} onClose={() => setDialogOpenIssueToUser(false)}/>
        </>
      )}
    </Section>
  );
}

export default ControlSection;