import React from "react";
import { Autocomplete, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import {
  DownloadRounded as DownloadIcon,
  UploadRounded as UploadIcon,
} from "@mui/icons-material";
import { FileRejection } from "react-dropzone";
import TestkitAPI from "../../api/endpoints/testkit";
import { ApiErrorResponse } from "../../api/types/base";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectSites } from "../../redux/slices/account";
import { DialogCloseHandler } from "../../types/dialog";
import { TestkitStatusAPI } from "../../types/testkit";
import { displayDropzoneErrors } from "../../utils/files";
import { getBulletPointLine } from "./utils";
import { Toast } from "../../components/Toast";
import FileDropzone from "../../components/FileDropzone";
import BaseDialog from "../../components/dialogs/BaseDialog";
import { SiteData } from "../../types/account";
import { getTestkitsInf } from "../../redux/slices/testkit";
import { REFRESH_DELAY } from "../../utils/global";
import { showErrorMessage } from "../../redux/utils";

const DialogDispatchKits = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const sites = useSelector(selectSites);

  const [site, setSite] = React.useState<SiteData | null>(null);
  const [files, setFiles] = React.useState<File[]>([]);

  const handleFilesChanged = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    displayDropzoneErrors(rejectedFiles);
    setFiles([...acceptedFiles]);
  }

  const handleClickDownload = () => {
    TestkitAPI.downloadAllKits({
      filename: 'testkits.csv',
      status: TestkitStatusAPI.ALLOCATED,
      siteId: site?.id,
      dispatched: false,
    })
    .catch((error: ApiErrorResponse) => {
      // console.error(error);
      showErrorMessage(error, { title: 'Failed to download CSV file' });
    });
  }

  const handleConfirm = () => {
    if (files.length > 0) {
      TestkitAPI.uploadDispatchedKitsCSV({ file: files[0] })
      .then(response => {
        Toast.success({ title: 'Updated testkit data' });
        onClose?.({}, 'closeClick');
        
        setTimeout(() => {
          // Repeat search with previous config after delay
          dispatch(getTestkitsInf({ refresh: true }));
        }, REFRESH_DELAY);
      })
      .catch((error: ApiErrorResponse) => {
        showErrorMessage(error, {
          title: 'Failed to upload CSV file',
          message: error.type === 'errorResponse' ? error.code?.description : undefined,
        });
      });
    }
  }

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      confirmText='Upload'
      confirmIcon={<UploadIcon/>}
      confirmDisabled={files.length === 0}
      onConfirm={handleConfirm}
      containerSx={{ flexDirection: 'row' }}
    >
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '600px', mr: 3 }}>
          <Typography variant='caption' sx={{ color: theme.palette.text.secondary }}>Step 1</Typography>
          <Typography>Download CSV data for all allocated kits awaiting dispatch</Typography>

          <Autocomplete
            disablePortal
            options={sites}
            getOptionLabel={site => site.name}
            isOptionEqualToValue={(option: any, value) => value.id === option.id}
            value={site}
            onChange={(e, value) => { setSite(value) }}
            sx={{ my: 2 }}
            renderInput={params => <TextField
              {...params}
              required
              name='site'
              label='Site'
            />}
          />

          <Button variant='text' disabled={!site} startIcon={<DownloadIcon/>} onClick={handleClickDownload} sx={{ mb: 4, width: 'fit-content' }}>Download</Button>

          <Typography variant='caption' sx={{ color: theme.palette.text.secondary }}>Step 2</Typography>
          <Typography>Edit CSV data in Excel or a text editor:</Typography>
          {getBulletPointLine('Delete rows you don\'t want to update')}
          {getBulletPointLine('Add dispatch dates to all kits')}
          {getBulletPointLine('(format: "YYYY-MM-DD#")', false)}
          <Typography variant='caption' color='secondary'>Remove all entries for kits you do not wish to update</Typography>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', width: '400px' }}>
          <Typography variant='caption' sx={{ color: theme.palette.text.secondary }}>Step 3</Typography>
          <Typography>Upload updated CSV data</Typography>

          <FileDropzone
            helperText='Drag & drop CSV file here, or click to select file'
            onFilesChanged={handleFilesChanged}
            sx={{ mt: 2, flex: 0 }}
          />
        </Box>
      </>
    </BaseDialog>
  );
}

export default DialogDispatchKits;