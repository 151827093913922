import { Autocomplete, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectMidwives, transferAllToMidwife } from "../../redux/slices/account";
import { getAccountFullName, removeUndefined, validateNotEmpty } from "../../utils/global";
import { DialogCloseHandler } from "../../types/dialog";
import { AccountData } from "../../types/account";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";

type TransferData = {
  newMidwife: AccountData | null,
}

const DialogTransferAllUsers = ({
  currentMidwife,
  open,
  onClose,
}: {
  currentMidwife?: AccountData,
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const midwives = useSelector(selectMidwives);

  const handleValidate = ({ newMidwife }: TransferData) => removeUndefined({
    newMidwife: validateNotEmpty(newMidwife?.username),
  })

  const handleSubmit = ({ newMidwife }: TransferData) => {
    if (!currentMidwife || !newMidwife) return;

    dispatch(transferAllToMidwife({ oldMidwife: currentMidwife, newMidwife }))
    .then(() => { onClose?.({}, 'closeClick') });
  }

  return (
    <BaseFormDialog
      title={`Transfer all users assigned to ${getAccountFullName(currentMidwife)} to a different midwife`}
      open={open}
      onClose={onClose}
      initialValues={{ newMidwife: null } as TransferData}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleBlur,
        setFieldValue,
      }) => (
        <>
          <Autocomplete
            disablePortal
            value={values.newMidwife}
            options={midwives.filter((midwife) => midwife.username !== currentMidwife?.username)}
            getOptionLabel={midwife => getAccountFullName(midwife)}
            onChange={(e, val) => { setFieldValue('newMidwife', val) }}
            onBlur={handleBlur}
            isOptionEqualToValue={(option: any, value) => value.username === option.username}
            renderInput={params => <TextField
              {...params}
              required
              name='newMidwife'
              label='Transfer all users to'
              error={!!errors.newMidwife}
              helperText={errors.newMidwife}
            />}
          />

          <Typography variant='caption' sx={theme => ({ color: theme.palette.secondary.main, mt: 2 })}>
            Note: this process cannot be undone
          </Typography>
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogTransferAllUsers;