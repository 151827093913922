import React from "react";
import { SxProps } from "@mui/material";
import { FormikErrors } from "formik";
import TextInput from "../TextInput";

export type FormTextInputProps<T> = {
  required?: boolean,
  name: keyof(T) & string,
  label: React.ReactNode,
  helperText?: string,
  type?: React.HTMLInputTypeAttribute,
  /** If true, it defaults to the value of *name*. Provide a string to override this */
  autoComplete?: string | true,
  spellCheck?: boolean,
  values?: T,
  errors?: FormikErrors<T>,
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  sx?: SxProps,
}

const FormTextInput = <T extends object>({
  required,
  name,
  label,
  helperText,
  type = 'text',
  autoComplete,
  spellCheck = false,
  values,
  errors,
  onChange,
  onBlur,
  sx,
}: FormTextInputProps<T>) => {
  const error = errors && !!errors[name];

  return (
    <TextInput
      required={required}
      name={name}
      label={label}
      helperText={helperText}
      type={type}
      autoComplete={autoComplete}
      spellCheck={spellCheck}
      value={values ? values[name] as string : ''}
      error={error}
      errorText={error ? errors[name] as string : undefined}
      onChange={onChange}
      onBlur={onBlur}
      sx={sx}
    />
  );
}

export default FormTextInput;