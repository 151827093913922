import React from "react";
import { Navigate } from "react-router-dom";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useSelector } from "../../redux/hooks";
import { selectIsLoggedIn } from "../../redux/slices/account";
import { Screen, setScreenTitle } from "../../routes/screens";
import { ReactComponent as Logo } from '../../assets/Logo_HORIZONTAL_01.svg';
import Section from "../../components/Section";
import Login from "./Login";
import Register from "./Register";

const LoginScreen = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  
  const TABS = [
    { id: 'login', label: 'Login', element: <Login/> },
    { id: 'register', label: 'Register', element: <Register/> },
  ]

  const [tabIdx, setTabIdx] = React.useState(0);
  
  React.useEffect(() => {
    setScreenTitle(Screen.LOGIN);
  }, [])

  const handleChangeTab = (event: React.SyntheticEvent, newTab: any) => setTabIdx(newTab);
  
  return !isLoggedIn ? (
    <Box sx={{
      display: 'flex', flexDirection: 'column',
      width: '100%', height: '100vh',
      justifyContent: 'center', alignItems: 'center',
    }}>
      <Section sx={{ mb: 1 }}>
        <Logo width={373} height={85}/>
        
        <Tabs variant='fullWidth' value={tabIdx} onChange={handleChangeTab}>
          {TABS.map((tab, index) => (
            <Tab key={index} value={index} label={tab.label}/>
          ))}
        </Tabs>

        {TABS[tabIdx].element}
      </Section>
      
      <Typography variant='caption' color={theme => theme.palette.text.secondary}>v{process.env.REACT_APP_VERSION}</Typography>
    </Box>
  ) : (
    <Navigate to='/users'/>
  );
}

export default LoginScreen;