import AutoSizer from 'react-virtualized-auto-sizer';
import { Box, Typography, useTheme } from '@mui/material';
import { InfiniteTableProps, InfiniteTableRendererProps } from './types';
import { BLANK } from '../../utils/global';
import { headerRenderer, infiniteTableRenderer } from './renderers';

const InfiniteTable = <T extends object>({
  width,
  height,
  headerHeight = 54,
  rowHeight = 36,
  marginLeft = 24,
  marginRight = 24,
  columnSpacing = 12,
  columns,
  items,
  totalItems,
  selectedItemIdx = -1,
  minBatchSize = 10,
  threshold = 20,
  isLoading,
  noResultsText = BLANK,
  sorter,
  rowRenderer,
  getRowTextColour,
  loadMoreItems,
  // checkItemLoaded,
  onClickRow,
  onSortColumn,
  sx,
}: InfiniteTableProps<T>) => {
  const theme = useTheme();

  const rowWidth = width || columns.reduce<number>((total, column) => {
    return columns ? total + column.width + columnSpacing : total;
  }, marginLeft + marginRight - columnSpacing);

  const tableRendererProps: InfiniteTableRendererProps<T> = {
    columns,
    items,
    totalItems,
    isLoading,
    selectedItemIdx,
    minBatchSize,
    threshold,
    tableHeight: 0,
    rowWidth,
    rowHeight,
    columnSpacing,
    ml: marginLeft,
    mr: marginRight,
    theme,
    rowRenderer,
    getRowTextColour,
    loadMoreItems,
    // checkItemLoaded,
    onClickRow,
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 auto',
      overflow: 'auto hidden',
      ...sx,
    }}>
      {headerRenderer({
        columns,
        rowWidth,
        headerHeight,
        columnSpacing,
        ml: marginLeft,
        mr: marginRight,
        sorter,
        theme,
        onSortColumn,
      })}
      
      {(totalItems < 0) ? (         // Total < 0 -> No items loaded yet -> Show loading icon
        <Typography variant='h6' sx={{ width: rowWidth - marginLeft - marginRight, ml: `${marginLeft}px`, mr: `${marginRight}px`, mt: 1 }}>
          Loading...
        </Typography>
      ) : (totalItems === 0) ? (    // Total = 0 -> No items match filter
        <Typography variant='h6' sx={{ width: rowWidth - marginLeft - marginRight, ml: `${marginLeft}px`, mr: `${marginRight}px`, mt: 1 }}>
          {noResultsText}
        </Typography>
      ) : height ? infiniteTableRenderer({ ...tableRendererProps, tableHeight: height }) : (    // Total > 0 -> Show items
        <Box sx={{ flex: '1 1 auto' }}>
          <AutoSizer>
            {({ height }) => infiniteTableRenderer({ ...tableRendererProps, tableHeight: height })}
          </AutoSizer>
        </Box>
      )}

      {/* {(isLoading && items.length === 0) ? (
        <Typography variant='h6' sx={{ width: rowWidth - marginLeft - marginRight, ml: `${marginLeft}px`, mr: `${marginRight}px`, mt: 1 }}>
          {noResultsText}
        </Typography>
      ) : height ? tableRenderer({ ...tableRendererProps, tableHeight: height }) : (
        <Box sx={{ flex: '1 1 auto' }}>
          <AutoSizer>
            {({ height }) => tableRenderer({ ...tableRendererProps, tableHeight: height })}
          </AutoSizer>
        </Box>
      )} */}
    </Box>
  );
}

export default InfiniteTable;