import { AccountData } from "../types/account"
import { getAccountFullName } from "./global";

// const RESEARCH_MIDWIFES = [
//   'basia.chmielewska@nhs.net',
// ];

export const isResearchMidwife = (midwife: AccountData | string) => {
  const email = (typeof(midwife) === 'string') ? midwife : midwife.username;

  return email === process.env.REACT_APP_RESEARCH_MIDWIFE;
  // if (typeof(midwife) === 'string') {
  //   return RESEARCH_MIDWIFES.includes(midwife);
  // }
  // else {
  //   return RESEARCH_MIDWIFES.includes(midwife.username);
  // }
}

export const sortMidwives = (accounts: AccountData[]) => {
  accounts.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
}

export const getMidwfeNames = (accounts: AccountData[]) => {
  const names: { [key: string]: string } = {};
  accounts.forEach(midwife => { names[midwife.username] = getAccountFullName(midwife) });
  return names;
}