import { useDispatch } from "../../redux/hooks";
import { promoteMidwife } from "../../redux/slices/account";
import { getAccountFullName } from "../../utils/global";
import { DialogCloseHandler } from "../../types/dialog";
import { AccountData } from "../../types/account";
import BaseDialog from "../../components/dialogs/BaseDialog";

const DialogPromote = ({
  midwife,
  open,
  onClose,
}: {
  midwife?: AccountData,
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  
  const handleConfirm = () => {
    if (!midwife) return;

    dispatch(promoteMidwife({ midwife: midwife.username }))
    .then(() => { onClose?.({}, 'closeClick') });
  }

  return (
    <BaseDialog
      title={`Grant ${getAccountFullName(midwife)} access to all sites`}
      width={300}
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
}

export default DialogPromote;