import { AccountRole } from "../types/account";
import { Patient } from "../types/patient";
import { Testkit, TestkitStatusAPI } from "../types/testkit";
import { isoDateTimeToDateTime } from "./dateTime";
import { BLANK } from "./global";

export const DEFAULT_API_COUNT = 100;
export const DEFAULT_API_BATCH_SIZE = 20;

export const getStatusTimestamp = ({ testkit, status }: { testkit?: Testkit, status: TestkitStatusAPI }) => {
  return testkit ? testkit.transitions.find((transition) => transition.status === status)?.timestamp : undefined;
}

export const getStatusTimestampDateTime = ({ testkit, status }: { testkit?: Testkit, status: TestkitStatusAPI }) => {
  const timestamp = getStatusTimestamp({ testkit, status });

  return timestamp ? isoDateTimeToDateTime(timestamp) : undefined;
}

export const removePatientIdPrefix = (id: string) => {
  return id.replace('P-', '');
}

export const removePatientIdPrefixPatients = (patients: Patient[]) => {
  patients.forEach(patient => { patient.id = removePatientIdPrefix(patient.id) });
}

export const removePatientIdPrefixTestkits = (testkits: Testkit[]) => {
  testkits.forEach(kit => { kit.patient = kit.patient && removePatientIdPrefix(kit.patient) });
}

export const getTestkitLocation = ({ kit, role, sites }: { kit: Testkit, role?: AccountRole, sites: {[key: string]: string} }) => {
  switch (kit.status) {
    // case TestkitAPIStatus.SUB_ASSEMBLY:
    //   return kit.manufacturer;
    case TestkitStatusAPI.STOCK:
      return 'MIAT';
    case TestkitStatusAPI.ALLOCATED:
      if (!kit.dispatched) {
        return role === AccountRole.ADMIN ? 'MIAT' : 'Sponsor (MIAT)'
      }
      else {
        return `Sent to ${(kit.site && sites[kit.site]) || 'trial site'}`
      }
    case TestkitStatusAPI.ISSUED:
      return 'Issued to user';
    default:
      return BLANK;
  }
}