import axios, { AxiosResponse } from 'axios';
import { ApiErrorResponse, ApiRequest, ApiResponse, EndpointRequest } from '../types/base';
import { getResponseStatusTypeByStatus } from '../types/responseStatus';
import { ResponseCode, getResponseCodeByCode, getResponseCodeByID, getResponseCodeData } from '../types/responseCode';

const ApiCall = {
  axiosInstance: axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 5000,
  }),
  buildOptions: (options = {}) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };
    return { ...headers, ...options };
  },
  validateStatus: (status: number) => {
    // HTTP statuses outside this range will trigger Axios errors
    return status >= 200 && status < 300;
  },
  processResponse: <T>(response: AxiosResponse<any>) => ({
    type: 'successResponse',
    status: response.status,
    statusType: getResponseStatusTypeByStatus(response.status),
    code: response.data?.code ? getResponseCodeByCode(response.data.code) : getResponseCodeByID(ResponseCode.Success),
    data: response.data,
  } as ApiResponse<T>),
  processResponseError: (response?: AxiosResponse) => {
    // console.log('processResponseError')
    // console.log(response)
    if (response) {
      return {
        type: 'errorResponse',
        status: response.data.status,
        statusType: getResponseStatusTypeByStatus(response.status),
        code: getResponseCodeData(response.data),
      } as ApiErrorResponse;
    }
    else {
      return { type: 'noResponse' } as ApiErrorResponse;
    }
  },
  processError: (error: any) => {
    // console.log('processError')
    // console.log({...error, stack: undefined})
    let result: ApiErrorResponse;

    if (!!error.type) {
      result = error;
    }
    // Request was made and server responded with non-2XX status code
    else if (!!error.response) {
      result = ApiCall.processResponseError(error.response);
    }
    // Error occurred while setting up request
    else if (!error.request || error.code === 'ERR_CANCELED') {
      result = { type: 'noRequest' } as ApiErrorResponse;
    }
    // Request was made but no response was received
    else {
      result = { type: 'noResponse' } as ApiErrorResponse;
    }

    return Promise.reject(result);
  },

  call: async (method: 'get' | 'post' | 'put' | 'delete', {
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: ApiRequest) => {
    const response = await ApiCall.axiosInstance({
      method,
      url: endpoint,
      params,
      data,
      headers: ApiCall.buildOptions(options),
      signal,
      validateStatus: ApiCall.validateStatus,
    })
    .then(ApiCall.processResponse)
    .catch(ApiCall.processError)

    return response;
  },
  get: async ({
    endpoint,
    params = undefined,
    options = {},
    signal,
  }: ApiRequest) => ApiCall.call('get', { endpoint, params, options, signal }),
  post: async ({
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: ApiRequest) => ApiCall.call('post', { endpoint, params, data, options, signal }),
  put: async ({
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: ApiRequest) => ApiCall.call('put', { endpoint, params, data, options, signal }),
  delete: async ({
    endpoint,
    params = undefined,
    options = {},
    signal,
  }: ApiRequest) => ApiCall.call('delete', { endpoint, params, options, signal }),

  /**
   * Adapted from: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
   */
  download: async ({
    method,
    filename = 'data.csv',
    endpoint,
    params = undefined,
    data = undefined,
    options = {},
    signal,
  }: {
    method: 'get' | 'post',
    filename?: string,
  } & ApiRequest) => ApiCall.axiosInstance({
    method,
    url: endpoint,
    params,
    data,
    headers: ApiCall.buildOptions(options),
    signal,
    responseType: 'blob',
  })
  .then(response => {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    
    link.setAttribute('download', filename);
    document.body.append(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }),
  
  healthCheck: async ({ signal }: EndpointRequest) => ApiCall.call('get', { endpoint: '/open/healthcheck', signal }),
};

export default ApiCall;
