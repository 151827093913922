import { DateTime } from "luxon"
import { ISODateString } from "./misc"

export type CrfData = {
  id: string,

  // Baseline
  base_estimatedDueDate?: DateTime,
  base_maternalAge?: string,

  base_ethnicity?: CrfEthnicity,

  base_height?: string,
  base_weight?: string,
  base_heightWeightTakenDate?: DateTime,

  obs_obstetricParity?: CrfParity,
  obs_previousPreeclampsia?: boolean,
  obs_previousChronicPreeclampsia?: boolean,
  obs_previousHypertension?: boolean,
  obs_previousRelatedOutcomes?: boolean,
  obs_prematureBirth?: boolean,
  obs_stillBirth?: boolean,
  obs_placentalAbruption?: boolean,
  obs_fgr?: boolean,
  obs_gdm?: boolean,
  obs_previousOther?: boolean,
  obs_comments?: string,

  med_cardiacDisease?: boolean,
  med_autoimmuneDisease?: boolean,
  med_renalDisease?: boolean,
  med_chronicHypertension?: boolean,
  med_diabetesType1?: boolean,
  med_diabetesType2?: boolean,
  med_comments?: string,

  smoke_smokingType?: CrfSmoking,
  smoke_comments?: string,

  // Clinical Diagnostics
  clin_asprePreeclampsiaRisk?: string,
  clin_sbp?: string,
  clin_dbp?: string,
  clin_bloodPressureTestDate?: DateTime,
  clin_pappAMom?: string,
  clin_pappATestDate?: DateTime,
  clin_pigfValue?: string,
  clin_pigfValueDate?: DateTime,
  clin_crl?: string,
  clin_crlTestDate?: DateTime,
  clin_uterineArteryPiLeft?: string,
  clin_uterineArteryPiRight?: string,
  clin_prescribedAspirin?: boolean,

  clin_preeclampsiaWithProteinuria?: boolean,
  clin_preeclampsiaWithProteinuriaDate?: DateTime,
  clin_severePreeclampsia?: boolean,
  clin_severeHypertension?: boolean,
  clin_severeHypertensionDate?: DateTime,
  clin_severePreeclampsiaWithSymptoms?: boolean,
  clin_severePreeclampsiaWithSymptomsDate?: DateTime,
  clin_severePreeclampsiaWithBiochemical?: boolean,
  clin_severePreeclampsiaWithBiochemicalDate?: DateTime,
  clin_severePreeclampsiaWithHaematological?: boolean,
  clin_severePreeclampsiaWithHaematologicalDate?: DateTime,
  clin_severePreeclampsiaComments?: string,
  clin_preeclampsia?: boolean,
  clin_eclampsiaDate?: DateTime,
  
  clin_pih?: boolean,
  clin_mildPregnancyHypertension?: boolean,
  clin_firstMildPregnancyHypertensionDate?: DateTime,
  clin_moderatePregnancyHypertension?: boolean,
  clin_firstModeratePregnancyHypertensionDate?: DateTime,
  clin_severePregnancyHypertension?: boolean,
  clin_firstSeverePregnancyHypertensionDate?: DateTime,
  clin_pregnancyHypertensionComments?: string,
  
  clin_proteinuria?: boolean,
  clin_anyUrinary?: boolean,
  clin_anyUrinaryDate?: DateTime,
  clin_significantUrinaryProtein?: boolean,
  clin_significantUrinaryProteinDate?: DateTime,
  
  clin_hellp?: boolean,
  clin_hellpDate?: DateTime,
  clin_asatAlat?: boolean,
  clin_lowPlatelets?: boolean,
  clin_hemolysis?: boolean,

  // Preeclampsia Symptoms
  pe_none?: boolean,
  pe_headache?: boolean,
  pe_headacheDate?: DateTime,
  pe_blurredVision?: boolean,
  pe_blurredVisionDate?: DateTime,
  pe_epigastricPain?: boolean,
  pe_epigastricPainDate?: DateTime,
  pe_vomitting?: boolean,
  pe_vomittingDate?: DateTime,
  pe_oedema?: boolean,
  pe_oedemaDate?: DateTime,
  pe_comments?: string,

  // Maternal Outcomes
  maternal_cns?: boolean,
  maternal_gdm?: boolean,
  maternal_cardiorespiratory?: boolean,
  maternal_haematological?: boolean,
  maternal_hdu?: boolean,
  maternal_placentalAbruption?: boolean,
  maternal_iv?: boolean,
  maternal_comments?: string,

  // Last Ultrasound
  ultra_scanDate?: DateTime,
  ultra_gestationalAge?: string,
  ultra_estimatedFetalWeight?: string,
  ultra_umbilicalArteryPi?: string,
  ultra_cerebralArteryPindex?: string,
  ultra_uterineArteryPiLeft?: string,
  ultra_uterineArteryPiRight?: string,

  // NeonatalOutcomes
  neonatal_sga?: boolean,
  neonatal_rds?: boolean,
  neonatal_bpd?: boolean,
  neonatal_ivh?: boolean,
  neonatal_rop?: boolean,
  neonatal_nec?: boolean,
  neonatal_prematureBirth?: boolean,
  neonatal_nicuAdmission?: boolean,
  neonatal_nicuAdmissionDate?: DateTime,
  neonatal_cpap?: boolean,
  neonatal_intubated?: boolean,
  neonatal_daysOnVentilation?: string,
  neonatal_other?: boolean,
  neonatal_comments?: string,

  // Birth Data
  birth_birth?: CrfBirth,
  birth_dateOfBirth?: DateTime,
  birth_birthOffset?: string,
  birth_labour?: CrfLabour,
  birth_deliveryMode?: CrfDeliveryMode,
  birth_steroidDoses?: string,
  birth_steroidDays?: string,
  birth_sex?: CrfSex,
  birth_weight?: string,
  birth_comments?: string,
}

export type CrfDataApi = {
  id: string,
  baselineInformation?: CrfBaselineInfoApi,
  clinicalDiagnostics?: CrfClinicalDiagnosticsApi,
}

export type CrfBaselineInfoApi = {
  estimatedDueDate?: ISODateString,
  maternalAge?: number,
  
  ethnicity?: CrfEthnicity,
  
  height?: number,
  weight?: number,
  heightWeightTakenDate?: ISODateString,
  
  obstetricHistory?: CrfObstetricHistoryApi,
  medicalHistory?: CrfMedicalHistoryApi,
  smokingHistory?: CrfSmokinglHistoryApi,
}

export type CrfObstetricHistoryApi = {
  obstetricParity?: CrfParity,
  
  previousPreeclampsia?: boolean,
  previousChronicPreeclampsia?: boolean,
  previousHypertension?: boolean,
  previousRelatedOutcomes?: boolean,

  prematureBirth?: boolean,
  stillBirth?: boolean,
  placentalAbruption?: boolean,
  fgr?: boolean,
  gdm?: boolean,
  previousOther?: boolean,

  comments?: string,
}

export type CrfMedicalHistoryApi = {
  cardiacDisease?: boolean,
  autoimmuneDisease?: boolean,
  renalDisease?: boolean,
  chronicHypertension?: boolean,
  
  diabetesType1?: boolean,
  diabetesType2?: boolean,

  comments?: string,
}

export type CrfSmokinglHistoryApi = {
  smokingType?: CrfSmoking,
  comments?: string,
}

export type CrfClinicalDiagnosticsApi = {
  asprePreeclampsiaRisk?: number,

  sbp?: number,
  dbp?: number,
  bloodPressureTestDate?: ISODateString,
  
  pappAMom?: number,
  pappATestDate?: ISODateString,
  
  pigfValue?: number,
  pigfValueDate?: ISODateString,
  
  crl?: number,
  crlTestDate?: ISODateString,
  
  uterineArteryPiLeft?: number,
  uterineArteryPiRight?: number,

  prescribedAspirin?: boolean,
  
  preeclampsiaWithProteinuria?: boolean,
  preeclampsiaWithProteinuriaDate?: ISODateString,
  
  severePreeclampsia?: boolean,
  severeHypertension?: boolean,
  severeHypertensionDate?: ISODateString,
  severePreeclampsiaWithSymptoms?: boolean,
  severePreeclampsiaWithSymptomsDate?: ISODateString,
  severePreeclampsiaWithBiochemical?: boolean,
  severePreeclampsiaWithBiochemicalDate?: ISODateString,
  severePreeclampsiaWithHaematological?: boolean,
  severePreeclampsiaWithHaematologicalDate?: ISODateString,
  severePreeclampsiaComments?: string,
  
  preeclampsia?: boolean,
  eclampsiaDate?: string,
  
  pih?: boolean,
  mildPregnancyHypertension?: boolean,
  firstMildPregnancyHypertensionDate?: ISODateString,
  moderatePregnancyHypertension?: boolean,
  firstModeratePregnancyHypertensionDate?: ISODateString,
  severePregnancyHypertension?: boolean,
  firstSeverePregnancyHypertensionDate?: ISODateString,
  pregnancyHypertensionComments?: string,
  
  proteinuria?: boolean,
  anyUrinary?: boolean,
  anyUrinaryDate?: ISODateString,
  significantUrinaryProtein?: boolean,
  significantUrinaryProteinDate?: ISODateString,
  
  hellp?: boolean,
  hellpDate?: ISODateString,
  asatAlat?: boolean,
  lowPlatelets?: boolean,
  hemolysis?: boolean,
  
  preeclampsiaSymptoms?: CrfPreeclampsiaSymptomsApi,
  maternalOutcomes?: CrfMaternalOutcomesApi,
  lastUltrasoundData?: CrfUltrasoundDataApi,
  neonatalOutcomes?: CrfNeonatalOutcomesApi,
  birthData?: CrfBirthDataApi,
}

export type CrfPreeclampsiaSymptomsApi = {
  none?: boolean,
  headache?: boolean,
  headacheDate?: ISODateString,
  blurredVision?: boolean,
  blurredVisionDate?: ISODateString,
  epigastricPain?: boolean,
  epigastricPainDate?: ISODateString,
  vomitting?: boolean,
  vomittingDate?: ISODateString,
  oedema?: boolean,
  oedemaDate?: ISODateString,
  comments?: string,
}

export type CrfMaternalOutcomesApi = {
  cns?: boolean,
  gdm?: boolean,
  cardiorespiratory?: boolean,
  haematological?: boolean,
  hdu?: boolean,
  placentalAbruption?: boolean,
  iv?: boolean,
  comments?: string,
}

export type CrfUltrasoundDataApi = {
  scanDate?: ISODateString,
  gestationalAge?: number,
  estimatedFetalWeight?: number,
  umbilicalArteryPi?: number,
  cerebralArteryPindex?: number,
  uterineArteryPiLeft?: number,
  uterineArteryPiRight?: number,
}

export type CrfNeonatalOutcomesApi = {
  sga?: boolean,
  rds?: boolean,
  bpd?: boolean,
  ivh?: boolean,
  rop?: boolean,
  nec?: boolean,
  prematureBirth?: boolean,
  nicuAdmission?: boolean,
  nicuAdmissionDate?: ISODateString,
  cpap?: boolean,
  intubated?: boolean,
  daysOnVentilation?: number,
  other?: boolean,
  comments?: string,
}

export type CrfBirthDataApi = {
  birth?: CrfBirth,
  dateOfBirth?: ISODateString,
  birthOffset?: number,
  labour?: CrfLabour,
  deliveryMode?: CrfDeliveryMode,
  steroidDoses?: number,
  steroidDays?: number,
  sex?: CrfSex,
  weight?: number,
  comments?: string,
}

export type MultiOption<T> = { id: T, label: string, hint?: string }

export enum CrfEthnicity {
  Asian = 'ASIAN',
  Black = 'BLACK',
  White = 'WHITE',
  Mixed = 'MIXED',
  Other = 'OTHER',
  Undisclosed = 'UNDISCLOSED',
}

export const CrfEthnicityData: MultiOption<CrfEthnicity>[] = [
  { id: CrfEthnicity.Asian, label: 'Asian or Asian British', hint: '(includes any Asian background, for example Bangladeshi, Chinese, Indian, Pakistani)' },
  { id: CrfEthnicity.Black, label: 'Black African, Black British or Caribbean', hint: '(includes any Black background)' },
  { id: CrfEthnicity.White, label: 'White', hint: '(includes any White background)' },
  { id: CrfEthnicity.Mixed, label: 'Mixed or multiple ethnic groups', hint: '(includes any Mixed background)' },
  { id: CrfEthnicity.Other, label: 'Another ethnic group', hint: '(includes any ethnic background, for example Arab)' },
  { id: CrfEthnicity.Undisclosed, label: 'Prefer not to say' },
]

export enum CrfParity {
  One = 'ONCE',
  Two = 'TWICE',
  More = 'MORE',
  Na = 'NOT_APPLICABLE',
}

export const CrfParityData: MultiOption<CrfParity>[] = [
  { id: CrfParity.Na, label: 'Para 0' },
  { id: CrfParity.One, label: 'Para 1' },
  { id: CrfParity.Two, label: 'Para 2' },
  { id: CrfParity.More, label: 'More' },
]

export enum CrfSmoking {
  Current = 'CURRENT',
  Former = 'FORMER',
  Never = 'NEVER',
}

export const CrfSmokingData: MultiOption<CrfSmoking>[] = [
  { id: CrfSmoking.Current, label: 'Current smoker' },
  { id: CrfSmoking.Former, label: 'Former smoker' },
  { id: CrfSmoking.Never, label: 'Never smoked' },
]

export enum CrfBirth {
  Live = 'LIVE',
  Stillbirth = 'STILLBIRTH',
}

export const CrfBirthData: MultiOption<CrfBirth>[] = [
  { id: CrfBirth.Live, label: 'Live' },
  { id: CrfBirth.Stillbirth, label: 'Stillbirth' },
]

export enum CrfLabour {
  Spontaneous = 'SPONTANEOUS',
  Induced = 'INDUCED',
  ELCS = 'ELCS',
}

export const CrfLabourData: MultiOption<CrfLabour>[] = [
  { id: CrfLabour.Spontaneous, label: 'Spontaneous' },
  { id: CrfLabour.Induced, label: 'Induced' },
  { id: CrfLabour.ELCS, label: 'N/A (ELCS)' },
]

export enum CrfDeliveryMode {
  Vaginal = 'VAGINAL',
  Assisted = 'ASSISTED',
  Caesarean = 'CAESAREAN',
  EmergencyCaesarean = 'EMERGENCY_CAESAREAN',
}

export const CrfDeliveryModeData: MultiOption<CrfDeliveryMode>[] = [
  { id: CrfDeliveryMode.Vaginal, label: 'Vaginal' },
  { id: CrfDeliveryMode.Assisted, label: 'Assisted delivery' },
  { id: CrfDeliveryMode.Caesarean, label: 'Caesarean section' },
  { id: CrfDeliveryMode.EmergencyCaesarean, label: 'Emergency caesarean section' },
]

export enum CrfSex {
  Male = 'MALE',
  Female = 'FEMALE',
}

export const CrfSexData: MultiOption<CrfSex>[] = [
  { id: CrfSex.Male, label: 'Male' },
  { id: CrfSex.Female, label: 'Female' },
]

export enum CrfSteroids {
  None = '0',
  One = '1',
  Two = '2',
}

export const CrfSteroidsData: MultiOption<CrfSteroids>[] = [
  { id: CrfSteroids.None, label: 'No' },
  { id: CrfSteroids.One, label: 'One Dose' },
  { id: CrfSteroids.Two, label: 'Two Doses' },
]
