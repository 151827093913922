import React from "react";
import { GenericAbortSignal } from "axios";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "../../redux/hooks";
import { addSecondaryID } from "../../redux/slices/patient";
import { selectRole, selectSiteNames } from "../../redux/slices/account";
import { searchTestkits, selectTestkitSearchResults } from "../../redux/slices/testkit";
import { Patient } from "../../types/patient";
import { AccountRole } from "../../types/account";
import { DialogCloseHandler } from "../../types/dialog";
import { Testkit, TestkitStatusUI } from "../../types/testkit";
import { removeUndefined, validateNotEmpty } from "../../utils/global";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";

type AddIdData = {
  kit: Testkit | null,
}

const DialogAddSecondID = ({
  open,
  primaryKit,
  onClose,
}: {
  open: boolean,
  primaryKit?: Patient,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);
  const kitSearchResults = useSelector(selectTestkitSearchResults);

  React.useEffect(() => {
    if (open) {
      const controller = new AbortController();    
      findTestkits({ signal: controller.signal });
      return () => { controller.abort() }
    }
  }, [open]);

  const findTestkits = ({ idPattern, signal }: { idPattern?: string, signal?: GenericAbortSignal }) => {
    dispatch(searchTestkits({
      idPattern,
      siteId: primaryKit?.site || undefined,
      status: TestkitStatusUI.ALLOCATED,
      signal,
    }));
  }

  const handleInputChangeID = (e: React.SyntheticEvent<Element, Event> | null, idPattern: string) => {
    // 'change' event -> user is typing a partial ID -> search using input as filter
    // 'click' or 'keydown' event -> user has selected/cleared ID -> clear filter
    findTestkits({ idPattern: e?.type === 'change' ? idPattern : undefined });
  }

  const handleValidate = ({ kit }: AddIdData) => removeUndefined({
    kit: validateNotEmpty(kit?.id),
  })

  const handleSubmit = ({ kit }: AddIdData) => {
    if (!kit || !primaryKit) return;

    dispatch(addSecondaryID({ primaryId: primaryKit.id, secondaryId: kit.id }))
    .then(() => { onClose?.({}, 'closeClick') })
    .catch(() => {})
  }

  return (
    <BaseFormDialog
      title={`Issue additional testkit to user ${primaryKit?.id}`}
      open={open}
      onClose={onClose}
      initialValues={{ kit: null } as AddIdData}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleBlur,
        setFieldValue,
      }) => (
        <>
          {role !== AccountRole.MEDIC && (
            <Autocomplete
              disabled
              disablePortal
              value={primaryKit?.site && sites[primaryKit.site]}
              options={[]}
              renderInput={params => <TextField {...params} label='Site'/>}
              sx={{ mb: 2 }}
            />
          )}

          <Autocomplete
            disablePortal
            value={values.kit}
            options={kitSearchResults}
            getOptionLabel={kit => kit.id}
            onChange={(e, val) => { setFieldValue('kit', val) }}
            onInputChange={handleInputChangeID}
            onBlur={handleBlur}
            isOptionEqualToValue={(option: any, value) => value.id === option.id}
            renderInput={params => <TextField
              {...params}
              required
              name='kit'
              label='ID'
              error={!!errors.kit}
              helperText={errors.kit}
            />}
          />
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogAddSecondID;