import React, { HTMLAttributes } from "react";
import { FormikValues } from "formik";
import { DateTime } from "luxon";
import { Autocomplete, AutocompleteOwnerState, AutocompleteRenderOptionState, Box, Checkbox, FormControlLabel, SxProps, TextField } from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import { DatePicker } from "@mui/x-date-pickers";
import IconButton from "./IconButton";

export type FilterText = { type: 'text', label: string, defaultValue?: string, width?: number, disableSearchOnEnterKey?: boolean }
export type FilterBoolean<T> = { type: 'boolean', label: string, defaultValue?: boolean, override?: boolean | ((values: T) => boolean | undefined) }
export type FilterDate = { type: 'date', label: string, defaultValue?: DateTime }
export type FilterOptions<TOption> = {
  type: 'options',
  label: string,
  defaultValue?: TOption,
  width?: number,
  options: TOption[],
  getKey?: (value: TOption) => string,
  getLabel?: (value: TOption) => string,
  isEqual?: (a: TOption, b: TOption) => boolean,
  onInputChange?: (e: React.SyntheticEvent<Element, Event> | null, value: string) => void,
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TOption,
    state: AutocompleteRenderOptionState,
    ownerState: AutocompleteOwnerState<TOption, false, false, false, 'div'>,
  ) => React.ReactNode,
}

export type Filter<T> = {
  id: string,
  disabled?: boolean | ((values: T) => boolean),
  visible?: boolean,
} & (FilterText | FilterBoolean<T> | FilterDate | FilterOptions<any>);

const getOverrides = <T,>(values: T, filters: Filter<T>[]) => {
  const finalValues: any = {};

  filters.forEach(filter => {
    if (filter.type === 'boolean') {
      const override = typeof(filter.override) === 'function' ? filter.override(values) : filter.override;

      if (override !== undefined) {
        finalValues[filter.id] = override;
      }
    }
  });

  return finalValues as T;
}

const getDefaultValue = <T,>(filter: Filter<T>) => {
  if (filter.defaultValue) {
    return filter.defaultValue;
  }
  else {
    switch (filter.type) {
      case 'text':
        return '';
      case 'boolean':
        return false;
      default:
        return null;
    }
  }
}

const getFilterComponent = <T extends FormikValues>(
  values: T,
  filter: Filter<T>,
  onChange: (id: string, value: any, triggerSearch?: boolean) => void,
  onSearch?: (values: T) => void,
) => {
  if (filter.visible !== undefined && !filter.visible) {
    return <React.Fragment key={filter.id}/>
  }

  const disabled = !!filter.disabled && (typeof(filter.disabled) === 'function' ? filter.disabled(values) : filter.disabled)

  switch (filter.type) {
    case 'text':
      return (
        <TextField
          key={filter.id}
          disabled={disabled}
          label={filter.label}
          value={values[filter.id] || ''}
          onChange={e => onChange(filter.id, e.target.value)}
          onKeyDown={e => { if (!filter.disableSearchOnEnterKey && e.key === 'Enter') { onSearch?.(values) }}}
          sx={{ width: `${filter.width || 160}px` }}
        />
      );
    case 'boolean':
      const override = typeof(filter.override) === 'function' ? filter.override(values) : filter.override;
      return (
        <FormControlLabel
          key={filter.id}
          disabled={disabled || override !== undefined}
          label={filter.label}
          control={<Checkbox
            checked={override ?? values[filter.id]}
            onChange={(_, val) => onChange(filter.id, val, true)}
          />}
          sx={{ mx: 0 }}
        />
      );
    case 'date':
      return (
        <DatePicker
          key={filter.id}
          disabled={disabled}
          label={filter.label}
          value={values[filter.id] || null}
          onChange={val => onChange(filter.id, val, true)}
          sx={{ width: 170 }}
        />
      );
    case 'options':
      return (
        <Autocomplete
          disablePortal
          key={filter.id}
          disabled={disabled}
          options={filter.options}
          value={values[filter.id] || null}
          onChange={(_, val) => onChange(filter.id, val, true)}
          onInputChange={filter.onInputChange}
          getOptionKey={filter.getKey}
          getOptionLabel={filter.getLabel}
          isOptionEqualToValue={filter.isEqual}
          sx={{ width: `${filter.width || 200}px` }}
          renderInput={params => <TextField {...params} label={filter.label}/>}
          renderOption={filter.renderOption}
        />
      );
  }
}

const SearchFilter = <T extends FormikValues> ({
  initialValues,
  filters,
  showClear = true,
  onSearch,
  sx,
}: {
  initialValues: T,
  filters: Filter<T>[],
  showClear?: boolean,
  onSearch?: (values: T) => void,
  sx?: SxProps,
}) => {
  const [rawValues, setRawValues] = React.useState<T>(initialValues);
  const [defaultValues, setDefaultValues] = React.useState<T | undefined>(undefined);
  const [filterElements, setFilterElements] = React.useState<React.ReactNode[]>([]);
  const [canClear, setCanClear] = React.useState(false);

  React.useEffect(() => {
    const newDefaults: any = {};
    const newFilters: React.ReactNode[] = [];
    const overrides = getOverrides(rawValues, filters);

    filters.forEach(filter => {
      newDefaults[filter.id] = getDefaultValue(filter);
      newFilters.push(getFilterComponent({ ...rawValues, ...overrides }, filter, handleChangeValue, onSearch));
    });

    setDefaultValues(newDefaults);
    setFilterElements(newFilters);
  }, [filters, rawValues]);

  React.useEffect(() => {
    if (!defaultValues) {
      return;
    }

    const keys = Object.keys(defaultValues)
    let anyDiff = false;
    
    // Check if any values are different to their initial value
    for (let i = 0; i < keys.length; i++) {
      if (defaultValues[keys[i]] !== rawValues[keys[i]]) {
        anyDiff = true;
        break;
      }
    }
    
    // If any are different -> show clear button, otherwise hide it
    if (anyDiff !== canClear) {
      setCanClear(anyDiff);
    }
  }, [defaultValues, rawValues, canClear, setCanClear]);

  const handleChangeValue = (id: string, value: any, triggerSearch: boolean = false) => {
    const newValues = { ...rawValues, [id]: value };
    setRawValues(newValues);

    if (triggerSearch) {
      onSearch?.({ ...newValues, ...getOverrides(newValues, filters) });
    }
  }

  const handleClearValues = (triggerSearch: boolean = true) => {
    if (!defaultValues) {
      return;
    }

    const newValues = { ...defaultValues };
    setRawValues(newValues);

    if (triggerSearch) {
      onSearch?.({ ...newValues, ...getOverrides(newValues, filters) });
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, ...sx }}>
      <IconButton
        variant='contained'
        title='Filter results'
        onClick={() => onSearch?.({ ...rawValues, ...getOverrides(rawValues, filters) })}
        sx={{ mr: 1 }}
      ><SearchIcon/></IconButton>
      
      {filterElements}

      {showClear && canClear && (
        <IconButton variant='icon' onClick={() => handleClearValues()}><ClearIcon/></IconButton>
      )}
    </Box>
  );
}

export default SearchFilter;