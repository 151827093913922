import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { ImageMetadata, ImageMetadataUI, ImageType } from "../types/image";
import { getImageSrc } from "../utils/image";

type Orientation = 'horizontal' | 'vertical';

const DEFAULT_LOADING_WIDTH  = 200;
const DEFAULT_LOADING_HEIGHT = 160;

const Image = ({
  image,
  imageType,
  alt,
  maxWidth = 0,
  maxHeight = 0,
  fadeDuration = 500,
  forceOrientation,
  onLoad,
  onError,
}: {
  image?: ImageMetadata | ImageMetadataUI,
  imageType: ImageType,
  alt: string,
  maxWidth?: number,
  maxHeight?: number,
  fadeDuration?: number,
  forceOrientation?: Orientation,
  onLoad?: React.ReactEventHandler<HTMLImageElement>,
  onError?: React.ReactEventHandler<HTMLImageElement>,
}) => {
  const theme = useTheme();

  const LOADING_WIDTH  = maxWidth  < DEFAULT_LOADING_WIDTH  ? maxWidth  : DEFAULT_LOADING_WIDTH;
  const LOADING_HEIGHT = maxHeight < DEFAULT_LOADING_HEIGHT ? maxHeight : DEFAULT_LOADING_HEIGHT;

  const [src, setSrc] = React.useState<string | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);

  const [rawSize, setRawSize] = React.useState<{ width: number, height: number }>({ width: 0, height: 0 });
  const [renderParams, setRenderParams] = React.useState({
    imageWidth:  LOADING_WIDTH,
    imageHeight: LOADING_HEIGHT,
    containerWidth:  LOADING_WIDTH,
    containerHeight: LOADING_HEIGHT,
    rotation: 0,
  });
  React.useEffect(() => {
    setSrc(getImageSrc({ image, type: imageType }))
  }, [image, imageType]);

  React.useEffect(() => {
    setError(false);
    setLoading(true);
  }, [src]);

  React.useEffect(() => {
    if (!loading) {
      const rawOrientation  = rawSize.width > rawSize.height ? 'horizontal' : 'vertical';
      const flipOrientation = !!forceOrientation && forceOrientation != rawOrientation;

      let adjustedWidth  = flipOrientation ? rawSize.height : rawSize.width;
      let adjustedHeight = flipOrientation ? rawSize.width  : rawSize.height;

      if (maxWidth > 0 || maxHeight > 0) {
        const xScale = maxWidth  ? adjustedWidth  / maxWidth  : 0;
        const yScale = maxHeight ? adjustedHeight / maxHeight : 0;
        const scale = Math.max(xScale, yScale);

        adjustedWidth  /= scale;
        adjustedHeight /= scale;
      }

      let rotation = flipOrientation ? 90 : 0;

      if (imageType === 'raw' && image?.rotation && image.rotation >= 180) {
        rotation += 180;
      }

      setRenderParams({
        imageWidth:       flipOrientation ? adjustedHeight : adjustedWidth,
        imageHeight:      flipOrientation ? adjustedWidth  : adjustedHeight,
        containerWidth:   adjustedWidth,
        containerHeight:  adjustedHeight,
        rotation,
      });
    }
  }, [loading, rawSize, maxWidth, maxHeight]);

  const handleLoad: React.ReactEventHandler<HTMLImageElement> = (e) => {
    setLoading(false);

    const img = e.nativeEvent.target as HTMLImageElement;
    setRawSize({ width: img.naturalWidth, height: img.naturalHeight });

    onLoad?.(e);
  }

  const handleError: React.ReactEventHandler<HTMLImageElement> = (e) => {
    setError(true);

    onError?.(e);
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: renderParams.containerWidth, height: renderParams.containerHeight }}>
      <img
        src={src}
        alt={alt}
        width={loading ? 0 : renderParams.imageWidth}
        height={loading ? 0 : renderParams.imageHeight}
        onLoad={handleLoad}
        onError={handleError}
        style={{
          opacity: loading ? 0 : 1,
          transform: (renderParams.rotation) ? `rotate(${renderParams.rotation}deg)` : undefined,
          filter: loading ? 'brightness(20%) saturate(20%)' : 'brightness(100%) saturate(100%)',
          ...(fadeDuration && {
            transition: theme.transitions.create([ 'opacity', 'filter' ], { duration: fadeDuration }),
          })
        }}
      />
      {loading && (
        <>
          {!error ? (
            <CircularProgress/>
          ) : (
            <Typography>{alt}</Typography>
          )}
        </>
      )}
    </Box>
  )
}

export default Image;