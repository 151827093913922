import NumberInput from "../../components/NumberInput";
import BaseFormDialog from "../../components/dialogs/BaseFormDialog";
import { useDispatch } from "../../redux/hooks";
import { generateNewIDs, getTestkitsInf } from "../../redux/slices/testkit";
import { DialogCloseHandler } from "../../types/dialog";
import { REFRESH_DELAY, removeUndefined, validateInt } from "../../utils/global";

type NewIdData = {
  count: string,
}

const DialogGenerateNewIDs = ({
  open,
  onClose,
}: {
  open: boolean,
  onClose?: DialogCloseHandler,
}) => {
  const dispatch = useDispatch();

  const handleValidate = ({ count }: NewIdData) => removeUndefined({
    count: validateInt(count, 1, 200),
  })

  const handleSubmit = ({ count }: NewIdData) => {
    const newIds = Number.parseInt(count);

    dispatch(generateNewIDs({ count: newIds }))
    .then(() => {
      onClose && onClose({}, 'closeClick');
        
      setTimeout(() => {
        // Repeat search with previous config after delay
        dispatch(getTestkitsInf({ refresh: true }));
      }, REFRESH_DELAY);
    })
    .catch(() => {})
  }

  return (
    <BaseFormDialog
      title='Generate new Study IDs'
      open={open}
      onClose={onClose}
      initialValues={{ count: '' } as NewIdData}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
      }) => (
        <>
          <NumberInput
            required
            format={/^\d{0,3}$/}
            name='count'
            label='IDs to generate'
            value={values.count}
            error={!!errors.count}
            errorText={errors.count}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </>
      )}
    </BaseFormDialog>
  );
}

export default DialogGenerateNewIDs;