import React from "react";
import { Box, Button, Dialog, Divider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import ImageAPI from "../../api/endpoints/image";
import { useSelector } from "../../redux/hooks";
import { selectEmail, selectRole } from "../../redux/slices/account";
import { selectUricAcidResults } from "../../redux/slices/patient";
import { useWindowDimensions } from "../../utils/hooks/useWindowDimensions";
import { getImageStatusUILabel, ImageMetadataUI, ImageType } from "../../types/image";
import { AccountRole } from "../../types/account";
import { UricAcidData } from "../../types/patient";
import { dateTimeToString } from "../../utils/dateTime";
import { BLANK } from "../../utils/global";
import { isPowerAdmin } from "../../utils/auth";
import { Toast } from "../Toast";
import Parameter from "../Parameter";
import Image from "../Image";
import { ApiErrorResponse } from "../../api/types/base";
import { showErrorMessage } from "../../redux/utils";

const PARAM_TITLE_WIDTH = 90;
const PARAM_VALUE_WIDTH = 220;

const PARAM_SIZE = { titleWidth: PARAM_TITLE_WIDTH, valueWidth: PARAM_VALUE_WIDTH };

const DialogImage = ({
  image,
  showUricAcidData,
  open,
  setOpen,
}: {
  image?: ImageMetadataUI,
  showUricAcidData?: boolean,
  open: boolean,
  setOpen: (open: boolean) => void,
}) => {
  const role = useSelector(selectRole);
  const email = useSelector(selectEmail);
  const uricAcidResults = useSelector(selectUricAcidResults);

  const { width, height } = useWindowDimensions();

  const [imageType, setImageType] = React.useState<ImageType>('raw');
  const [uricAcidData, setUricAcidData] = React.useState<UricAcidData | undefined>(undefined);

  React.useEffect(() => {
    // If selected image type is unavailable for this image -> reset to raw image
    if (open && ((imageType === 'whiteBalance' && !image?.whitebalanceFileUrl) || (imageType === 'mask' && !image?.maskFileUrl))) {
      setImageType('raw');
    }
  }, [open, image, imageType]);

  React.useEffect(() => {
    if (!image || !uricAcidResults) {
      return;
    }

    const idx = uricAcidResults.results.findIndex(uaData => uaData.imageId === image?.id);

    if (idx !== undefined && idx >= 0) {
      setUricAcidData(uricAcidResults.results[idx]);
    }
    else {
      setUricAcidData(undefined);
    }
  }, [image, uricAcidResults]);

  const handleChangeImageType = (e: React.MouseEvent<HTMLElement>, newType: any) => {
    if (!!newType) {
      setImageType(newType);
    }
  }

  const handleClickReprocess = () => {
    if (image) {
      ImageAPI.reprocessImage({ imageId: image.id })
      .then(() => {
        Toast.info({ title: 'Added to queue for reprocessing' });
      })
      .catch((error: ApiErrorResponse) => {
        // console.error(error);
        showErrorMessage(error, { title: 'Failed to request image reprocessing' });
      });
    }
  }

  return (
    <Dialog open={open} onClose={() => {setOpen(false)}} maxWidth={false}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Image
          image={image}
          imageType={imageType}
          alt='Latest upload'
          maxWidth={Math.min(width - 64, 800)}
          maxHeight={height - 64}
          forceOrientation='vertical'
        />

        {role === AccountRole.ADMIN && (
          <Box sx={theme => ({
            display: 'flex', flexDirection: 'column', alignSelf: 'stretch',
            borderLeftStyle: 'solid', borderLeftWidth: 1, borderLeftColor: theme.palette.divider,
            px: 3, py: 2
          })}>
            <Typography variant='h5'>{image?.patient || BLANK}</Typography>
            <Typography variant='body2' color={theme => theme.palette.text.secondary}>{image?.id || BLANK}</Typography>

            <ToggleButtonGroup
              exclusive
              color='secondary'
              value={imageType}
              onChange={handleChangeImageType}
              sx={{ my: 2 }}
            >
              <ToggleButton value='raw'>Raw Image</ToggleButton>
              <ToggleButton value='whiteBalance' disabled={!image?.whitebalanceFileUrl}>White Balanced</ToggleButton>
              <ToggleButton value='mask' disabled={!image?.maskFileUrl}>Mask</ToggleButton>
            </ToggleButtonGroup>

            <Parameter title='Sampled' {...PARAM_SIZE} primaryValue={image?.imageTimestamp && dateTimeToString(image.imageTimestamp, true)}/>
            <Parameter title='Uploaded' {...PARAM_SIZE} primaryValue={image?.uploadTimestamp && dateTimeToString(image.uploadTimestamp, true)}/>
            <Parameter title='Processed' {...PARAM_SIZE} primaryValue={image?.processingTimestamp && dateTimeToString(image.processingTimestamp, true)}/>

            <Parameter title='Status' {...PARAM_SIZE} primaryValue={image && getImageStatusUILabel(image.status)} secondaryValue={image?.status} sx={{ mt: 2 }}/>

            {showUricAcidData && (
              <>
                <Divider sx={{ my: 1.5 }}/>
                <Parameter title='Week' {...PARAM_SIZE} primaryValue={uricAcidData?.week}/>
                <Parameter title='UA Status' {...PARAM_SIZE} primaryValue={uricAcidData?.status}/>
                <Parameter title='UA Risk' {...PARAM_SIZE} primaryValue={uricAcidData?.risk}/>

                <Parameter title='Uric Acid' {...PARAM_SIZE} primaryValue={uricAcidData?.uricAcidLevel} sx={{ mt: 2 }}/>
                <Parameter title='RGB (G)' {...PARAM_SIZE} primaryValue={uricAcidData?.baseUricAcidData?.rgbGreenUricAcid}/>
                <Parameter title='YCbCr (Y)' {...PARAM_SIZE} primaryValue={uricAcidData?.baseUricAcidData?.crcbyYUricAcid}/>
                <Parameter title='LAB (AB)' {...PARAM_SIZE} primaryValue={uricAcidData?.baseUricAcidData?.labABUricAcid}/>
              </>
            )}

            {isPowerAdmin(email) && (
              <Button sx={{ mt: 'auto', alignSelf: 'end' }} onClick={handleClickReprocess}>
                Reprocess Image
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
}

export default DialogImage;