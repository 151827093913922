import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { ApiErrorResponse, EndpointRequest, FilterPagination, FilterPaginationDateTime } from "../../api/types/base";
import { showErrorMessage } from "../utils";
import { convertFilterToAPI, convertSorterToAPI, FinishTrialReason, Patient, PatientFilterAPI, PatientFilterUI, PatientSorterUI, PatientUricAcidResults } from "../../types/patient";
import PatientAPI from "../../api/endpoints/patient";
import { ImageMetadata, ImageStatus } from "../../types/image";
import ImageAPI from "../../api/endpoints/image";
import { DateTime } from "luxon";
import { calcPregnancyWeek } from "../../utils/dateTime";
import { Toast } from "../../components/Toast";
import { AccountData } from "../../types/account";
import { getAccountFullName, mergeArrays, removeUndefined } from "../../utils/global";
import { removePatientIdPrefix } from "../../utils/testkit";
import { ResponseCode } from "../../api/types/responseCode";

interface PatientState {
  patientFilter: PatientFilterUI,
  patientSorter: PatientSorterUI,
  isLoadingPatients: boolean,
  totalPatients: number,
  patientsInf: Patient[],
  selectedPatient?: Patient,

  isLoadingPatientSearchResults: boolean,
  patientSearchResults: Patient[],

  isLoadingPatientImages: boolean,
  patientImages?: ImageMetadata[],
  displayedImage?: ImageMetadata,

  isLoadingUricAcidResults: boolean,
  uricAcidResults?: PatientUricAcidResults,
}

const initialState: PatientState = {
  patientFilter: { idPattern: '', midwife: null, includeCompleted: false },
  patientSorter: { column: 'id', desc: false },
  isLoadingPatients: false,
  totalPatients: -1,
  patientsInf: [],
  selectedPatient: undefined,

  isLoadingPatientSearchResults: false,
  patientSearchResults: [],

  isLoadingPatientImages: false,
  patientImages: undefined,
  displayedImage: undefined,

  isLoadingUricAcidResults: false,
  uricAcidResults: undefined,
}

// NOTE: 'state' is only mutable inside 'createSlice'
export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    clearCachedPatients: (state) => {
      state.totalPatients = -1;
      state.patientsInf = [];
      state.selectedPatient = undefined;
    },
    clearCachedPatientSearchResults: (state) => {
      state.patientSearchResults = [];
    },
    clearPatientImages: (state) => {
      state.patientImages = undefined;
    },
    clearUricAcidResults: (state) => {
      state.uricAcidResults = undefined;
    },
    setSelectedPatient: (state, action: PayloadAction<Patient | undefined>) => {
      state.selectedPatient = action.payload;

      if (!action.payload) {
        state.uricAcidResults = undefined;
      }
    },
    setDisplayedImage: (state, action: PayloadAction<ImageMetadata | undefined>) => {
      state.displayedImage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Thunk: getPatientsInf
    builder.addCase(getPatientsInf.pending, (state, action) => {
      state.isLoadingPatients = true;

      // If specified, update stored filter
      if (action.meta.arg.filter) {
        state.patientFilter = action.meta.arg.filter;
      }
      
      // If specified, update stored sorter
      if (action.meta.arg.sorter) {
        state.patientSorter = action.meta.arg.sorter;
      }
    });
    builder.addCase(getPatientsInf.fulfilled, (state, action) => {
      state.isLoadingPatients = false;

      const initTotal = state.totalPatients;
      // const offset = action.meta.arg.offset || 0;
      // const limit  = action.meta.arg.limit || 100;
      const patients: Patient[] = action.payload?.[0].data || [];

      if (action.payload?.length > 1) {
        state.totalPatients = action.payload[1].data?.count;
      }

      // console.log(`${offset}-${offset + limit - 1}, Results: ${patients.length}`)

      // removePatientIdPrefixTestkits(patients);
      patients.forEach(patient => {
        patient.id = removePatientIdPrefix(patient.id);

        if (patient.week <= 0) {
          patient.week = calcPregnancyWeek(DateTime.fromISO(patient.dueDate));
        }
      });
      
      // If refreshing or if filter/sorter changed -> reset list of kits
      if (initTotal < 0 || action.meta.arg.refresh || action.meta.arg.filter || action.meta.arg.sorter) {
        state.selectedPatient = undefined;
        state.patientsInf = patients;

        // console.log('Resetting list of kits')
      }
      // Otherwise -> merge new kits into existing list
      else {
        state.patientsInf = mergeArrays(state.patientsInf, patients, (a, b) => a.id === b.id)

        // console.log('Appending results to list of kits')
      }

      // console.log(state.stockTestkitsInf)
    });
    builder.addCase(getPatientsInf.rejected, (state, action) => {
      state.isLoadingPatients = false;
      console.warn(action)
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve testkit stock data' });
    });

    // Thunk: getPatientByID
    builder.addCase(getPatientByID.fulfilled, (state, action) => {
      const patient = action.payload.data;
      patient.id = removePatientIdPrefix(patient.id);
      state.selectedPatient = patient;
    });
    builder.addCase(getPatientByID.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to retrieve data for user ${action.meta.arg.id}` });
    });
    
    // Thunk: searchPatients
    builder.addCase(searchPatients.pending, (state, action) => { state.isLoadingPatientSearchResults = true; });
    builder.addCase(searchPatients.fulfilled, (state, action) => {
      state.isLoadingPatientSearchResults = false;

      const patients = action.payload?.data || [];

      patients.forEach(patient => {
        patient.id = removePatientIdPrefix(patient.id);

        if (patient.week <= 0) {
          patient.week = calcPregnancyWeek(DateTime.fromISO(patient.dueDate));
        }
      });

      state.patientSearchResults = patients;
    });
    builder.addCase(searchPatients.rejected, (state, action) => {
      state.isLoadingPatientSearchResults = false;
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve testkit search results' });
    });

    // Thunk: getAllImages
    builder.addCase(getAllImages.pending, (state, action) => {state.isLoadingPatientImages = true; });
    builder.addCase(getAllImages.fulfilled, (state, action) => {
      state.isLoadingPatientImages = false;

      const images = action.payload?.data || [];

      images.forEach(image => {
        image.patient = image.patient && removePatientIdPrefix(image.patient);
      });

      state.patientImages = images;
    });
    builder.addCase(getAllImages.rejected, (state, action) => {
      state.isLoadingPatientImages = false;
      
      showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve image data' });
    });
    
    // Thunk: addUser
    builder.addCase(addUser.fulfilled, (state, action) => {
      Toast.success({ title: `Assigned user to kit ${action.meta.arg.id}` });
    });
    builder.addCase(addUser.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to assign user to kit ${action.meta.arg.id}` });
    });
    
    // Thunk: addSecondaryID
    builder.addCase(addSecondaryID.fulfilled, (state, action) => {
      Toast.success({ title: `Issued kit ${action.meta.arg.secondaryId} to user ${action.meta.arg.primaryId}` });
    });
    builder.addCase(addSecondaryID.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to issue kit to user ${action.meta.arg.primaryId}` });
    });
    
    // Thunk: transferToMidwife
    builder.addCase(transferToMidwife.fulfilled, (state, action) => {
      const idx = state.patientsInf.findIndex(patient => patient.id === action.meta.arg.patient.id);
      state.patientsInf[idx].midwife = action.payload.data.midwife;
      state.patientsInf[idx].site = action.payload.data.site;
      
      // Selected patient should always be the patient being transferred, but check anyway
      if (state.selectedPatient?.id === action.meta.arg.patient.id) {
        state.selectedPatient = state.patientsInf[idx];
      }

      Toast.success({ title: `Transferred user ${action.meta.arg.patient.id} to ${getAccountFullName(action.meta.arg.midwife)}` });
    });
    builder.addCase(transferToMidwife.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to transfer user to ${getAccountFullName(action.meta.arg.midwife)}` });
    });
    
    // Thunk: completePregnancy
    builder.addCase(completePregnancy.fulfilled, (state, action) => {
      if (state.patientFilter?.includeCompleted) {
        // Include finished -> update user info with response data
        const idx = state.patientsInf.findIndex(patient => patient.id === action.meta.arg.id);

        if (idx >= 0) {
          const patient = {
            ...action.payload.data,
            id: action.meta.arg.id,
          };
          
          state.patientsInf = [...state.patientsInf];
          state.patientsInf[idx] = patient;
          state.selectedPatient = patient;
        }
      }
      else {
        // Exclude finished -> hide user from list
        state.patientsInf = state.patientsInf.filter(patient => patient.id !== action.meta.arg.id);
        state.selectedPatient = undefined;
      }

      Toast.success({ title: `Finished trial for user ${action.meta.arg.id}` });
    });
    builder.addCase(completePregnancy.rejected, (state, action) => {
      showErrorMessage(action.payload as ApiErrorResponse, { title: `Failed to finish trial for user ${action.meta.arg.id}` });
    });

    // Thunk: getUricAcidResults
    builder.addCase(getUricAcidResults.pending, (state, action) => {state.isLoadingUricAcidResults = true; });
    builder.addCase(getUricAcidResults.fulfilled, (state, action) => {
      state.isLoadingUricAcidResults = false;
      state.uricAcidResults = action.payload?.data || undefined;
    });
    builder.addCase(getUricAcidResults.rejected, (state, action) => {
      state.isLoadingUricAcidResults = false;
      
      const error = action.payload as ApiErrorResponse;
      const noDataFound = error.type === 'errorResponse' && error.status === 404 && error.code?.id === ResponseCode.EntityNotFound;

      // ID is valid but has no Uric Acid data yet
      if (noDataFound) {
        state.uricAcidResults = undefined;
      }
      else {
        showErrorMessage(action.payload as ApiErrorResponse, { title: 'Failed to retrieve uric acid data' });
      }
    });
  },
});

export default patientSlice.reducer;
export const {
  clearCachedPatients,
  clearCachedPatientSearchResults,
  clearPatientImages,
  clearUricAcidResults,
  setSelectedPatient,
  setDisplayedImage,
} = patientSlice.actions;

export const getPatientsInf = createAsyncThunk('patient/getPatientsInf',
  async ({ filter, sorter, limit, offset, refresh, signal }: {
    filter?: PatientFilterUI,
    sorter?: PatientSorterUI,
    refresh?: boolean,
  } & FilterPagination & EndpointRequest, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const totalItems = selectTotalPatients(state);

    const uiFilter = filter || selectPatientFilter(state);
    const uiSorter = sorter || selectPatientSorter(state);

    const params = {
      ...convertFilterToAPI(uiFilter),
      ...convertSorterToAPI(uiSorter),
      limit,
      offset,
      signal,
    };

    const apiCalls: any[] = [
      PatientAPI.getAllPatients(params).catch(error => rejectWithValue(error)),
    ]

    // If refreshing or if filter/sorter changed -> fetch total stock count
    if ((totalItems < 0) || refresh || filter || sorter) {
      // Call API to fetch total count
      apiCalls.push(PatientAPI.getPatientCount(params).catch(error => rejectWithValue(error)));
    }

    return Promise.all(apiCalls).catch(error => rejectWithValue(error));
  },
);

export const getPatientByID = createAsyncThunk('patient/getPatientByID',
  async ({ id, signal }: { id: string } & EndpointRequest, { rejectWithValue }) => {
    return PatientAPI.getPatientByID({ id, signal })
    .catch(error => rejectWithValue(error));
  },
);

export const searchPatients = createAsyncThunk('patient/searchPatients',
  async ({ signal, ...filter }: PatientFilterAPI & EndpointRequest, { rejectWithValue }) => {
    return PatientAPI.getAllPatients({
      ...removeUndefined(filter),
      limit: 50,
      signal,
    })
    .catch(error => rejectWithValue(error))
  },
);

export const getAllImages = createAsyncThunk('patient/getAllImages',
  async ({ id, status, startTime, endTime, limit, offset, signal }: { id?: string, status?: ImageStatus } & FilterPaginationDateTime & EndpointRequest, { rejectWithValue }) => {
    if (id) {
      return ImageAPI.getImagesByPatient({ id, startTime, endTime, limit, offset, signal })
      .catch(error => rejectWithValue(error))
    }
    else if (status) {
      return ImageAPI.getImagesByStatus({ status, startTime, endTime, limit, offset, signal })
      .catch(error => rejectWithValue(error))
    }
    else {
      return ImageAPI.getAllImages({ startTime, endTime, limit, offset, signal })
      .catch(error => rejectWithValue(error))
    }
  },
);

export const addUser = createAsyncThunk('patient/addUser',
  async ({ id, dueDate, signal }: { id: string, dueDate: DateTime } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.issueKitToPatient({ id, dueDate: dueDate.toFormat('yyyy-MM-dd') || '', signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const addSecondaryID = createAsyncThunk('patient/addSecondaryID',
  async ({ primaryId, secondaryId, signal }: { primaryId: string, secondaryId: string } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.issueKitToExistingPatient({ primaryId, secondaryId, signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const transferToMidwife = createAsyncThunk('patient/transferToMidwife',
  async ({ patient, midwife, siteId, signal }: { patient: Patient, midwife: AccountData, siteId?: string } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.transferPatientToMidwife({ id: patient.id, oldMidwife: patient.midwife, newMidwife: midwife.username, newSiteId: siteId, signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const completePregnancy = createAsyncThunk('patient/completePregnancy',
  async ({ id, reason, signal }: { id: string, reason: FinishTrialReason } & EndpointRequest, { rejectWithValue }) => (
    PatientAPI.completePregnancy({ id, reason, signal })
    .catch(error => rejectWithValue(error))
  ),
);

export const getUricAcidResults = createAsyncThunk('patient/getUricAcidResults',
  async ({ id, signal }: { id: string } & EndpointRequest, { rejectWithValue }) => (
      PatientAPI.getUricAcidResults({ id, signal })
      .catch(error => rejectWithValue(error))
  ),
);

export const selectPatientFilter = (state: RootState) => state.patient.patientFilter;
export const selectPatientSorter = (state: RootState) => state.patient.patientSorter;
export const selectIsLoadingPatients = (state: RootState) => state.patient.isLoadingPatients;
export const selectTotalPatients = (state: RootState) => state.patient.totalPatients;
export const selectPatientsInf = (state: RootState) => state.patient.patientsInf;
export const selectSelectedPatient = (state: RootState) => state.patient.selectedPatient;

export const selectIsLoadingPatientSearchResults = (state: RootState) => state.patient.isLoadingPatientSearchResults;
export const selectPatientSearchResults = (state: RootState) => state.patient.patientSearchResults;

export const selectIsLoadingPatientImages = (state: RootState) => state.patient.isLoadingPatientImages;
export const selectPatientImages = (state: RootState) => state.patient.patientImages;
export const selectDisplayedImage = (state: RootState) => state.patient.displayedImage;

export const selectIsLoadingUricAcidResults = (state: RootState) => state.patient.isLoadingUricAcidResults;
export const selectUricAcidResults = (state: RootState) => state.patient.uricAcidResults;
