import { DateTime } from "luxon";
import { BLANK } from "../utils/global";

export type ImageType = 'raw' | 'whiteBalance' | 'mask';

export enum ImageStatus {
  /** Fatal technical failure, e.g. server failed to save the image */
  FatalError              = 'FATAL_ERROR',
  /** Newly arrived image. Transient state: only seen if data retrieved after the image arrived but before the identification is completed. */
  New                     = 'NEW',
  /** Correctly identified both QR code and OCR. Transient state: only seen if data retrieved after identification but before colour segmentation. */
  Identified              = 'IDENTIFIED',
  /** Correctly identified QR code or OCR (not both). Transient state: only seen if data retrieved after identification but before colour segmentation. */
  PartiallyIdentified     = 'PARTIALLY_IDENTIFIED',
  /** Unable to identify kit ID. Terminal state: the AI won’t process the image any further. */
  Unidentified            = 'UNIDENTIFIED',
  /** Kit ID belongs to kit that hasn't been issued yet. Terminal state: the AI won’t process the image any further. */
  NotIssued               = 'NOT_ISSUED',
  /** Kit ID doesn't match uploader's ID. Terminal state: the AI won’t process the image any further. */
  WrongPatient            = 'WRONG_PATIENT',
  /** Correctly identified but failed in RGB colour segmentation (i.e. AI could not find the RGB squares). Terminal state: the AI won’t process the image any further. */
  ColourSegError          = 'COLOUR_SEGMENTATION_ERROR',
  /** RGB squares were correctly identified but the AI failed to find test window. Terminal state: the AI won’t process the image any further. */
  TestWindowError         = 'TEST_WINDOW_ERROR',
  /** Successfully processed image/ */
  ColourAnalysisSuccess   = 'COLOUR_ANALYSIS_SUCCESSFUL',
  /** Test window successfully mapped to uric acid level. */
  MedicalAnalysisSuccess  = 'MEDICAL_ANALYSIS_SUCCESSFUL',
}

export const IMAGE_STATUSES = Object.values(ImageStatus);

export const getImageStatusUILabel = (status?: ImageStatus) => {
  switch (status) {
    case ImageStatus.FatalError:
      return 'Fatal Error';
    case ImageStatus.New:
      return 'New';
    case ImageStatus.Identified:
      return 'Identified';
    case ImageStatus.PartiallyIdentified:
      return 'Partially-Identified';
    case ImageStatus.Unidentified:
      return 'Unidentified';
    case ImageStatus.NotIssued:
      return 'Not issued';
    case ImageStatus.WrongPatient:
      return 'Wrong ID';
    case ImageStatus.ColourSegError:
      return 'RGB Not Found';
    case ImageStatus.TestWindowError:
      return 'Test Paper Not Found';
    case ImageStatus.ColourAnalysisSuccess:
      return 'Completed (Colour)';
    case ImageStatus.MedicalAnalysisSuccess:
      return 'Completed (Uric Acid)';
    default:
      return BLANK;
  }
}

export const isImageValid = (image: ImageMetadata | ImageMetadataUI) => {
  switch (image.status) {
    case ImageStatus.New:
    case ImageStatus.Identified:
    case ImageStatus.ColourAnalysisSuccess:
    case ImageStatus.MedicalAnalysisSuccess:
      return true;

    default:
      return false;
  }
}

export type ImageMetadata = {
  objectType: 'image',
  /** UUID */
  id: string,
  /** Uploader's Study ID */
  patient?: string,
  /** Testkit ID in the image */
  testkit?: string,
  // username?: string,

  rawSampleFile: string,
  rawFileUrl: string,
  whitebalanceSampleFile?: string,
  whitebalanceFileUrl?: string,
  maskSampleFile?: string,
  maskFileUrl?: string,
  medicalSampleFile?: string,
  rotation: number,

  uploadFilename: string,
  uploadTimestamp: string,
  imageTimestamp?: string,
  processingTimestamp?: string,
  status: ImageStatus,
  statistics?: ImageStatistics,

  dummyFlag?: boolean,
}

export type ImageMetadataUI = {
  /** UUID */
  id: string,
  /** Uploader's Study ID */
  patient?: string,
  /** Testkit ID in the image */
  testkit?: string,
  // username?: string,

  rawSampleFile: string,
  rawFileUrl: string,
  whitebalanceSampleFile: string,
  whitebalanceFileUrl: string,
  maskSampleFile: string,
  maskFileUrl: string,
  medicalSampleFile?: string,
  rotation: number,

  uploadFilename: string,
  uploadTimestamp: DateTime,
  imageTimestamp: DateTime,
  processingTimestamp?: DateTime,
  status: ImageStatus,
  statistics?: ImageStatistics,

  dummyFlag?: boolean,
}

export const convertImageMetadataToUI = (image: ImageMetadata) => ({
  ...image,
  uploadTimestamp: DateTime.fromISO(image.uploadTimestamp, {zone: 'utc'}),
  imageTimestamp: DateTime.fromISO(image.imageTimestamp || image.uploadTimestamp, {zone: 'utc'}),
  processingTimestamp: image.processingTimestamp && DateTime.fromISO(image.processingTimestamp, {zone: 'utc'}),
} as ImageMetadataUI)

export const convertImageMetadataFromUI = (image: ImageMetadataUI) => ({
  ...image,
  uploadTimestamp: image.uploadTimestamp.toISO(),
  imageTimestamp: image.imageTimestamp.toISO(),
  processingTimestamp: image.processingTimestamp?.toISO(),
} as ImageMetadata)

export type ImageStatistics = {
  maxH?: number,
  stdH?: number,
  rangeH?: number,
  medianH?: number,

  minS?: number,
  meanS?: number,
  stdS?: number,
  rangeS?: number,

  meanL?: number,
  meanA?: number,
  meanB?: number,

  stdY?: number,
  stdCb?: number,
  rangeCb?: number,
  minCr?: number,
  stdCr?: number,
  rangeCr?: number,
  medianCr?: number,

  rangeGreen?: number,
  minBlue?: number,
  maxBlue?: number,
}