import React from 'react';
import { DateTime } from 'luxon';
import { Box, Divider, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import { AccessTime as DelayedIcon, Error as InvalidIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from '../../redux/hooks';
import { selectMidwives, selectRole, selectSiteNames } from '../../redux/slices/account';
import { clearPatientImages, clearUricAcidResults, getAllImages, getPatientsInf, getUricAcidResults, selectIsLoadingPatients, selectPatientFilter, selectPatientSorter, selectPatientsInf, selectSelectedPatient, selectTotalPatients, setSelectedPatient } from '../../redux/slices/patient';
import { Patient, PatientFilterAPI, PatientFilterUI, PatientSorterUI } from '../../types/patient';
import { AccountData, AccountRole } from '../../types/account';
import { BLANK, getAccountFullName, removeUndefined } from '../../utils/global';
import { pregnancyWeekToString, isoDateTimeToDate, getDiffDays } from '../../utils/dateTime';
import { Column } from '../../components/Table/types';
import Section from '../../components/Section';
import SearchFilter from '../../components/SearchFilter';
import InfiniteTable from '../../components/Table/InfiniteTable';

const API_BATCH_SIZE = 50;
const WEEK_LIMIT_WARNING = 2;

const ROW_ID = 130;
const ROW_ID_WIDE = 300;
const ROW_SITE = 160;
const ROW_DUE_DATE = 135;
const ROW_WEEK_IDX = 55;
const ROW_LAST_SUBMISSION = 110;
const ROW_ERROR = 24;

const getLastSubmitText = (patient: Patient) => {
  const daysSinceSubmit = patient.lastImageTimestamp && getDiffDays(patient.lastImageTimestamp);

  if (daysSinceSubmit === undefined) {
    return BLANK;
  }
  else if (daysSinceSubmit === 0) {
    return 'Today';
  }
  else {
    return daysSinceSubmit === 1 ? '1 day ago' : `${daysSinceSubmit} days ago`;
  }
}

const getRowError = (patient: Patient) => {
  if (patient.finished) {
    return;
  }

  const daysSinceStart      = getDiffDays(patient.startTimestamp);
  const daysSinceSubmit     = patient.lastImageTimestamp ? getDiffDays(patient.lastImageTimestamp) : -1;
  const isLastSubmitRecent  = patient.lastImageTimestamp ? (Math.abs(DateTime.fromISO(patient.lastImageTimestamp, {zone: 'utc'}).diffNow('minutes').minutes) < 30) : false;
  
  const weeksSinceSubmit    = Math.floor(((patient.lastImageTimestamp ? daysSinceSubmit : daysSinceStart) - 1) / 7);

  let errorType: 'invalid' | 'delayed' | undefined = undefined;
  let errorColour: 'error' | 'warning' | undefined = undefined;
  let tooltip: string | undefined = undefined;

  // At least 1 week without a submission
  if (weeksSinceSubmit > 0) {
    errorType   = 'delayed';
    errorColour = weeksSinceSubmit >= WEEK_LIMIT_WARNING ? 'error' : 'warning';

    // No previous submission
    if (!patient.lastImageTimestamp) {
      tooltip = `Started ${weeksSinceSubmit}+ week${weeksSinceSubmit > 1 ? 's' : ''} ago - no submission yet`;
    }
    // At least 1 previous submission
    else {
      tooltip = `Last submission was ${weeksSinceSubmit}+ week${weeksSinceSubmit > 1 ? 's' : ''} ago`;
    }
  }
  // Last submission was not valid & more than 30 minutes ago (to give server time to process)
  else if ((patient.lastImageTimestamp !== patient.lastSuccessfulProcessingTimestamp) && !isLastSubmitRecent) {
    errorType = 'invalid';
    errorColour = 'error';
    tooltip = 'Last submission was invalid';
  }

  if (!!errorType) {
    return (
      <Tooltip title={tooltip} placement='right' disableInteractive>
        {errorType === 'invalid' ? <InvalidIcon color={errorColour}/> : <DelayedIcon color={errorColour}/>}
      </Tooltip>
    )
  }
}

const BASE_COLUMNS: Column<Patient>[] = [
  { id: 'id', label: 'Study ID', width: ROW_ID, sortable: true, getValue: (patient: Patient) => patient.id },
  { id: 'site', label: 'Site', width: ROW_SITE },
  { id: 'dueDate', label: 'Due Date (Ultrasound)', width: ROW_DUE_DATE, sortable: true, getValue: (patient: Patient) => patient?.dueDate ? isoDateTimeToDate(patient.dueDate) : BLANK },
  { id: 'weekIdx', label: 'Week', width: ROW_WEEK_IDX, getValue: (patient: Patient) => patient?.week !== undefined ? pregnancyWeekToString(patient.week) : BLANK },
  { id: 'lastSubmission', label: 'Last Submission', width: ROW_LAST_SUBMISSION, getValue: (patient: Patient) => getLastSubmitText(patient) },
  { id: 'error', label: undefined, width: ROW_ERROR, getValue: (patient: Patient) => getRowError(patient) },
];

const TableSection = ({
  sx,
}: {
  sx?: SxProps<Theme>,
}) => {
  const dispatch = useDispatch();

  const reduxFilter = useSelector(selectPatientFilter);
  const reduxSorter = useSelector(selectPatientSorter);
  const patients = useSelector(selectPatientsInf);
  const selectedPatient = useSelector(selectSelectedPatient);
  const isLoadingPatients = useSelector(selectIsLoadingPatients);
  const totalPatients = useSelector(selectTotalPatients);
  
  const role = useSelector(selectRole);
  const sites = useSelector(selectSiteNames);
  const midwives = useSelector(selectMidwives);

  const [columns, setColumns] = React.useState<Column<Patient>[]>([]);

  // De-select User when unloading the User screen
  React.useEffect(() => {
    return () => {
      dispatch(setSelectedPatient(undefined));
    }
  }, [dispatch]);

  // Setup columns
  React.useEffect(() => {
    let cols = [ ...BASE_COLUMNS ];

    if (role !== AccountRole.MEDIC) {
      cols[0].width = ROW_ID;
      cols[1].getValue = (item: Patient) => (item.site && sites[item.site]) || BLANK;
    }
    else {
      // Remove the 'Sites' column
      cols.splice(1, 1);
      cols[0].width = ROW_ID_WIDE;
    }

    // cols[0].onClick = handleClickColumn;
    setColumns(cols);
  // }, [role, sites, reduxSorter]);
  }, [role, sites]);

  const getRowTextColour = ({ item, theme }: { item: Patient, theme: Theme }) => {
    if (item.dummyFlag) {
      return theme.palette.info.main;
    }
    else if (item.finished) {
      return theme.palette.text.disabled;
    }
  }

  const handleSearch = (values: PatientFilterUI) => {
    dispatch(getPatientsInf({
      limit: API_BATCH_SIZE,
      offset: 0,
      filter: removeUndefined({
        idPattern: values.idPattern || undefined,
        midwife: values.midwife || undefined,
        includeCompleted: values.includeCompleted,
      } as PatientFilterAPI)
    }));
  }

  const handleClickRow = ({ index }: { index: number }) => {
    const patient = selectedPatient !== patients[index] ? patients[index] : undefined;
    dispatch(setSelectedPatient(patient));

    if (!!patient) {
      dispatch(getAllImages({ id: patient.id }));
      
      if (role === AccountRole.ADMIN) {
        dispatch(getUricAcidResults({ id: patient.id }));
      }
    }
    else {
      dispatch(clearPatientImages());
      dispatch(clearUricAcidResults());
    }
  }

  const handleClickColumn = ({ index, id }: { index: number, id: string }) => {
    // Clicked existing sort param -> change direction
    const sorter: PatientSorterUI = (reduxSorter.column === id) ? {
      column: reduxSorter.column, desc: !reduxSorter.desc,
    } : {
      column: id as any, desc: false,
    }

    dispatch(getPatientsInf({ limit: API_BATCH_SIZE, offset: 0, sorter }));
  }

  const loadItems = async (startIdx: number, stopIdx: number) => {
    // console.log(`Loading ${startIdx}-${stopIdx}. Total: ${totalPatients}, IsLoading: ${isLoadingPatients}`)

    dispatch(getPatientsInf({
      offset: startIdx,
      limit: stopIdx - startIdx + 1,
    }));
  }

  return (
    <Section sx={{ height: '100%', p: 0, ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', overflowX: 'auto', pl: 3, pr: 1, py: 2 }}>
        <SearchFilter
          initialValues={{ ...reduxFilter }}
          filters={[
            { type: 'text', id: 'idPattern', label: 'Study ID' },
            { type: 'options', id: 'midwife', label: 'Midwife', options: midwives, width: 240,
              getKey: (midwife: AccountData) => midwife.username,
              getLabel: (midwife: AccountData) => getAccountFullName(midwife),
              isEqual: (a: AccountData, b: AccountData) => a.username === b.username,
            },
            { type: 'boolean', id: 'includeCompleted', label: 'Finished' },
          ]}
          onSearch={handleSearch}
          sx={{ mr: 1 }}
        />
      </Box>

      <InfiniteTable
        totalItems={totalPatients >= 0 ? totalPatients : API_BATCH_SIZE}
        minBatchSize={API_BATCH_SIZE}

        columns={columns}
        items={patients}
        sorter={reduxSorter}
        selectedItemIdx={patients.findIndex(kit => kit === selectedPatient)}
        isLoading={isLoadingPatients}
        noResultsText='No Testkits Found'
        loadMoreItems={loadItems}
        getRowTextColour={getRowTextColour}
        onClickRow={handleClickRow}
        onSortColumn={handleClickColumn}
      />

      <Divider/>
      <Box sx={{ px: 3, py: 1 }}>
      <Typography color={theme => theme.palette.text.secondary}>Count: {totalPatients >= 0 ? totalPatients : BLANK}</Typography>
      </Box>
    </Section>
  );
}

export default TableSection;