import { Button } from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "../../redux/hooks";
import { registerNewAccount, selectIsLoggingIn } from "../../redux/slices/account";
import { RegistrationData } from "../../types/auth";
import { validateRegistration } from "../../utils/auth";
import FormTextInput from "../../components/form/FormTextInput";
import { Form } from "./Form";

/**
 * Dev mode sign-up codes:
 * Admin code:    njSD73wbda
 * Midwife code:  salurate
 * St Georges:    StGeorges1
 * St Heliers:    StHelier1
 */

const Register = () => {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(selectIsLoggingIn);

  const handleRegistration = (values: RegistrationData) => {
    dispatch(registerNewAccount(values));
  }

  return (
    <Formik
      initialValues={{ activationCode: '', email: '', firstName: '', lastName: '', password: '' }}
      validate={validateRegistration}
      onSubmit={handleRegistration}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FormTextInput
            required
            label='Sign-up Code'
            name='activationCode'
            values={values}
            errors={errors}
            onChange={handleChange}
            sx={{ mt: 3 }}
          />
          <FormTextInput
            required
            label='Email'
            name='email'
            type='email'
            autoComplete='email'
            values={values}
            errors={errors}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <FormTextInput
            required
            label='First Name'
            name='firstName'
            autoComplete='given-name'
            values={values}
            errors={errors}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <FormTextInput
            required
            label='Last Name'
            name='lastName'
            autoComplete='family-name'
            values={values}
            errors={errors}
            onChange={handleChange}
            sx={{ mt: 2 }}
          />
          <FormTextInput
            required
            label='Password'
            name='password'
            type='password'
            autoComplete='new-password'
            values={values}
            errors={errors}
            onChange={handleChange}
            sx={{ my: 2 }}
          />

          <Button type='submit' disabled={isSubmitting} variant='contained' sx={{ alignSelf: 'center' }}>
            Register
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default Register;