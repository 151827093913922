import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiResponse } from "../../api/types/base";
import { useDispatch, useSelector } from "../../redux/hooks";
import { selectRole } from "../../redux/slices/account";
import { clearCrfData, getCrfData } from "../../redux/slices/crf";
import { clearCachedPatientSearchResults, searchPatients, selectSelectedPatient, setSelectedPatient } from "../../redux/slices/patient";
import { Screen, setScreenTitle } from "../../routes/screens";
import { Patient } from "../../types/patient";
import { CrfSection } from "./inputs";
import ScreenBox from "../../components/ScreenBox";
import DataSection from "./DataSection";
import NavSection from "./NavSection";

const CrfScreen = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const selectedPatient = useSelector(selectSelectedPatient);

  const navigate = useNavigate();
  const { id: idParam } = useParams();   // Fetch 'id' param from URL

  const [openSection, setOpenSection] = React.useState<CrfSection | undefined>(CrfSection.Demographics);

  React.useEffect(() => {
    return () => {
      dispatch(clearCachedPatientSearchResults());
      dispatch(setSelectedPatient(undefined));
      dispatch(clearCrfData());
    }
  }, [dispatch]);

  React.useEffect(() => {
    const controller = new AbortController();
    setScreenTitle(Screen.CRF);

    dispatch(searchPatients({
      idPattern: idParam,
      includeCompleted: true,
      signal: controller.signal,
    }))
    .then((result) => {
      if (!!idParam && result.meta.requestStatus === 'fulfilled') {
        const payload = result.payload as ApiResponse<Patient[]>;
  
        // If URL param ID matches a valid patient - set it as the selected patient
        if (payload?.data?.length === 1) {
          dispatch(setSelectedPatient(payload.data[0]));
        }
        // Else -> clear search filter
        else {
          dispatch(setSelectedPatient(undefined));
          dispatch(searchPatients({
            includeCompleted: true,
            signal: controller.signal,
          }));

          navigate('/crf');
        }
      }
    })

    return () => { controller.abort() }
  }, [dispatch, role]);

  React.useEffect(() => {
    if (!!selectedPatient) {
      dispatch(getCrfData({ id: selectedPatient.id }));
    }
    else {
      dispatch(clearCrfData());
    }
  }, [dispatch, selectedPatient]);

  const onClickSection = (id: CrfSection) => {
    setOpenSection(openSection === id ? undefined : id)
  }

  const onChangeSelectedPatient = (patient: Patient | null) => {
    dispatch(setSelectedPatient(patient || undefined));
    navigate(!!patient ? `/crf/${patient.id}` : '/crf');
  }

  return (
    <ScreenBox>
      <NavSection
        openSection={openSection}
        patient={selectedPatient}
        onClickSection={onClickSection}
      />
      <DataSection
        openSection={openSection}
        patient={selectedPatient}
        onClickSection={onClickSection}
        onChangeSelectedPatient={onChangeSelectedPatient}
      />
    </ScreenBox>
  );
}

export default CrfScreen;