import { FixedSizeList } from 'react-window';
import { Box, ButtonBase, Divider, Typography, styled } from '@mui/material';
import {
  KeyboardArrowUp as UpIcon,
  KeyboardArrowDown as DownIcon,
} from "@mui/icons-material";
import { HeaderColumnRendererProps, HeaderRendererProps, InfiniteTableRendererProps, RowRendererProps, TableRendererProps } from "./types";
import InfiniteLoader from 'react-window-infinite-loader';

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const headerColumnRenderer = <T,>({
  index,
  column,
  ml,
  mr,
  sorter,
  onSortColumn,
} : HeaderColumnRendererProps<T>) => {
  const sizeSx = { width: column.width, pl: `${ml}px`, pr: `${mr}px` };
  const label = (
    <>
      <Typography key={column.id}>{column.label}</Typography>
      {!!sorter && sorter.column === column.id && (!sorter.desc ?
        <UpIcon color='disabled' sx={{ ml: 1 }}/> : <DownIcon color='disabled' sx={{ ml: 1 }}/>
      )}
    </>
  );

  return !onSortColumn ? (
    <Box key={column.id} sx={{ ...sizeSx }}>
      {label}
    </Box>
  ) : (
    <ButtonBase
      key={column.id}
      sx={{
        ...sizeSx,
        height: '100%',
        boxSizing: 'content-box',
        justifyContent: 'start',
        textAlign: 'start',
        ':hover': {
          bgcolor: 'action.hover',
        },
      }}
      onClick={() => onSortColumn({ index, id: column.id })}
    >
      {label}
    </ButtonBase>
  )
}

export const headerRenderer = <T,>({
  columns,
  rowWidth,
  headerHeight,
  columnSpacing,
  ml,
  mr,
  sorter,
  theme,
  onSortColumn,
}: HeaderRendererProps<T>) => (
  <>
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: rowWidth,
      height: `${headerHeight}px`,
    }}>
      {columns.map((column, index) => {
        if (column.getValue === undefined) {
          console.warn(`Column ${index} ("${column.id}") getValue is undefined`);
        }

        return headerColumnRenderer({
          index,
          column,
          ml: index === 0                  ? ml : columnSpacing,
          mr: index === columns.length - 1 ? mr : 0,
          sorter,
          theme,
          onSortColumn: column.sortable ? onSortColumn : undefined,
        })
      })}
    </Box>
    <Divider flexItem sx={{ position: 'sticky', left: 0 }}/>
  </>
)

export const defaultRowRenderer = <T,>({
  index,
  item,
  columns,
  selected,
  columnSpacing,
  ml,
  mr,
  style,
  theme,
  getRowTextColour,
  onClick,
}: RowRendererProps<T>) => {
  const textColour = getRowTextColour?.({ index, item, theme }) || theme.palette.text.primary;
  return (
    <Row
      onClick={onClick && (() => onClick({ index, item }))}
      style={{
        ...style,
        width: undefined,
        color: textColour,
        backgroundColor: selected ? theme.palette.action.selected : undefined,
      }}
      sx={{
        paddingLeft: `${ml}px`,
        paddingRight: `${mr}px`,
      }}
    >
      {columns.map((column, colIndex) => {
        const ml = colIndex !== 0 ? `${columnSpacing}px` : undefined;
        const contents = column.getValue?.(item);

        return typeof(contents) === 'string' ? (
          <Typography key={column.id} sx={{ width: column.width, ml }}>
            {contents}
          </Typography>
        ) : (
          <Box key={column.id} sx={{ width: column.width, ml, display: 'flex', alignItems: 'center' }}>
            {contents}
          </Box>
        );
      })}
    </Row>
  )
}

export const tableRenderer = <T,>({
  columns,
  items,
  selectedItemIdx,
  tableHeight,
  rowWidth,
  rowHeight,
  columnSpacing,
  ml,
  mr,
  theme,
  rowRenderer,
  getRowTextColour,
  onClickRow,
} : TableRendererProps<T>) => (
  <FixedSizeList
    width={rowWidth}
    height={tableHeight}
    itemSize={rowHeight}
    itemCount={items.length}
    style={{ overflowX: 'hidden' }}
  >
    {({ index, style }) => {
      const props = {
        index,
        item: items[index],
        columns,
        columnSpacing,
        selected: selectedItemIdx === index,
        ml,
        mr,
        style,
        theme,
        getRowTextColour,
        onClick: onClickRow,
      } as RowRendererProps<T>;

      return rowRenderer?.(props) || defaultRowRenderer(props);
    }}
  </FixedSizeList>
)

export const infiniteTableRenderer = <T,>({
  columns,
  items,
  totalItems,
  isLoading,
  selectedItemIdx,
  minBatchSize = 0,
  threshold,
  tableHeight,
  rowWidth,
  rowHeight,
  columnSpacing,
  ml,
  mr,
  theme,
  rowRenderer,
  getRowTextColour,
  loadMoreItems,
  // checkItemLoaded,
  onClickRow,
} : InfiniteTableRendererProps<T>) => {
  // If there are more items to load, add an extra row for the loading indicator
  // const itemCount = hasMoreItems ? items.length + minBatchSize : items.length;

  // Check if the specified row index has loaded
  const isItemLoaded = (index: number) => index < items.length;

  return (
    <InfiniteLoader
      itemCount={totalItems}
      minimumBatchSize={minBatchSize}
      isItemLoaded={isItemLoaded}     // Only load 1 batch of items at a time
      loadMoreItems={isLoading ? () => {} : loadMoreItems}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          ref={ref}
          width={rowWidth}
          height={tableHeight}
          itemSize={rowHeight}
          // itemCount={items.length}
          itemCount={totalItems}
          style={{ overflowX: 'hidden' }}
          onItemsRendered={onItemsRendered}
        >
          {({ index, style }) => {
            const props = {
              index,
              item: items[index],
              columns,
              columnSpacing,
              selected: selectedItemIdx === index,
              ml,
              mr,
              style,
              theme,
              getRowTextColour,
              onClick: onClickRow,
            } as RowRendererProps<T>;
      
            if (isItemLoaded(index)) {
              return rowRenderer?.(props) || defaultRowRenderer(props);
            }
            else {
              <div style={style}>Loading...</div>
            }
          }}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  )
}
